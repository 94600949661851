.collapse-card {
  display: flex;
  flex-direction: column;
  margin-top: -1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  width: calc(100% + 2.5rem);
  overflow-x: auto;

  @media screen and (max-width: 1279px) {
    overflow-x: auto;
  }

  &__controll {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    bottom: -4px;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 2;
    background: var(--white);
    width: 40px;
    margin: auto;

    .btn-resize {
      cursor: pointer;
      width: 20px;
      height: 8px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 9px;
      }
      // height: 10px;
    }

    .up {
      cursor: pointer;
    }

    .down {
      cursor: pointer;
    }
  }

  &__box {
    margin-right: 0.5rem;
    height: 100%;

    &--margin {
      margin-left: 20px;
      margin-right: 0;
      padding-top: 11px;
      padding-bottom: 10px;
    }

    .table-responsive {
      margin: 0;
      width: 100%;
    }
  }

  &__table {
    height: 100%; // hide firefox scrollbar border
  }

  &__description {
    .row {
      margin-left: -20px;
      margin-right: -20px;
    }

    .col {
      padding-left: 20px;
      padding-right: 20px;
    }

    .description {
      font-size: 13px;
      letter-spacing: 0.6px;
      padding: 4px 0;

      &--cols-1 {
        width: 100%;
      }

      &--cols-2 {
        width: 50%;
      }

      &--cols-3 {
        width: 33%;
      }

      &__label {
        color: var(--imo-grey-700);
        display: inline-block;
        padding-right: 4px;
      }

      &__text {
        color: var(--imo-grey-900);
        font-weight: bold;
        display: inline;
      }

      &--p {
        font-size: 14px;
        letter-spacing: 0.6px;
        color: var(--imo-grey-700);
        text-align: justify;
      }
    }
    .description-long {
      margin: 0;
      padding: 0;
      padding-left: 20px;

      @include media-breakpoint-up(mac) {
        padding-left: 30px;
      }
      // margin-left: 15px;
    }

    &__image {
      width: 240px;
      flex: 0 0 240px;
    }

    &__text {
      display: flex;
      flex-wrap: wrap;
      width: auto;
      margin-left: 30px;
      margin-right: 20px;
      min-width: 250px;
      justify-content: space-between;
    }

    &__page {
      width: 100%;
      text-align: right;
      letter-spacing: 0.6px;
      font-size: 13px;
      line-height: 1;
    }
    .image-container {
      display: flex;
      justify-content: center;
      max-height: 50vh;
      overflow: hidden;
      & img {
        object-fit: cover;
      }
    }
  }

  .custom-handle {
    position: absolute;
    bottom: 5px;
    width: 100%;
    height: 16px;
    z-index: 1;
    cursor: ns-resize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);

    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: calc(50% - 20px);
      height: 1px;
      background-color: var(--imo-grey-700);
    }
  }
}

.full-col-width {
  width: 100% !important;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.full {
  flex: 0 1 0 !important;
}

.left-pane,
.right-pane {
  box-shadow: 0px 3px 8px 0 var(--imo-grey-shadow);
  border-radius: 6px;
}
