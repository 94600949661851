.grid-box {
  height: 100%;
  .slick-headerrow-columns {
    width: fit-content;
  }
  .collapse-card {
    height: 100%;
    overflow-y: hidden;
  }

  &__controll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: -15px;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 101;
    width: 16px;
    height: 100px;
    margin: auto;

    &--0 {
      right: 0;
    }

    &--1 {
      right: 0;
      transform: translate(0px, 30%);
    }

    &--2 {
      right: 0px;
    }

    .btn-resize {
      cursor: pointer;
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 9px;
      }
    }

    .up {
      cursor: pointer;
    }

    .down {
      cursor: pointer;
    }
  }

  .card-header {
    padding-bottom: 25px;
    padding-top: 5px;
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 5px;
  }
}

.gutter.gutter-horizontal {
  cursor: ew-resize;
  background-color: var(--imo-grey-300);
}

.reflex-container.vertical > .reflex-splitter {
  position: relative;
  padding: 0 8px;
  height: 90vh !important;
  border: none !important;
  background: transparent !important;

  &:hover {
    border: none !important;
  }
}

.reflex-container > .reflex-element {
  width: auto !important;
  min-width: 0 !important;
  overflow: hidden !important;
}

.horizontal .reflex-container {
  background-color: white !important;
}

.horizontal > .reflex-splitter {
  position: relative;
  margin: 2px 0 !important;
  padding: 2px 3px !important;
  border: none !important;
  height: 1px !important;
  background-color: transparent !important;
  border-bottom: 1px solid var(--imo-grey-700) !important;
  z-index: 1 !important;
}
.card-header-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  & > div:first-child {
    margin-left: -12px;
  }
  .card-header-selected {
    cursor: default;
    padding: 12px;
  }
  .card-header-unselected {
    cursor: pointer;
    opacity: 0.5;
    padding: 12px;
    &:hover {
      opacity: 1;
    }
  }
}

div.grid-header-type-1.card-header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  > .row > .col-6 {
    padding-top: 5px;
  }
}
.grid-box.row:not(.grid-box-type-1) .collapse-card {
  height: calc(100% + 1.25rem);
}
div.grid-panel-type-1.reflex-element {
  overflow: unset !important;
  &[style*='flex: 0 1 0%'] {
    overflow: hidden !important;
  }
  margin-top: 51px;
  box-shadow: none;
  border-radius: 0;
  .collapse-card {
    margin-top: -1px;
  }
  div.collapse-card {
    margin-top: auto;
  }
  .grid-box-type-1 {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0px 3px 8px 0 var(--imo-grey-shadow);
  }
  .grid-header-type-1.card-header {
    position: absolute;
    top: -51px;
    width: 100%;
    background: transparent;
    padding: 0;
    .col-unselected-type-1 {
      cursor: pointer;
      transition: 0.2s ease;
      transition-property: background, box-shadow;
      border-radius: 6px 6px 0 0;
      &:hover {
        background: var(--white);
        box-shadow: 0px 3px 8px 3px var(--imo-grey-shadow);
        // naprawia zawinięty border-radius na gridzie podczas hovera
        &::after {
          content: '';
          width: 100%;
          height: 5px;
          background: var(--white);
          position: absolute;
          bottom: -5px;
          left: 0;
          border-top: 1px solid var(--imo-grey-600);
        }
        & + .col-selected-type-1 {
          z-index: -1;
        }
      }
    }
    .row {
      width: 100%;
      margin: 0;
      > .col-6 {
        display: flex;
        align-items: center;
        padding: 5px 1.25rem;
        width: 50%;
        height: auto;
        .row > div {
          padding: 0;
        }
      }
      .col-selected-type-1 {
        overflow: hidden;
        height: 51px;
        background: var(--white);
        border-radius: 6px 6px 0 0;
        box-shadow: 0px 3px 8px 0 var(--imo-grey-shadow);
      }
      .col-selected-type-1,
      .col-unselected-type-1 {
        & > .row {
          flex-wrap: nowrap;
        }
      }
    }
  }
  .grid-header.row {
    height: auto;
  }
}
.grid-header.row {
  flex-wrap: nowrap;
  height: 40.78px;
  .grid-header-dropdown {
    width: 20px;
    position: relative;
    left: -10px;
  }
}
.grid-header.row .grid-header-dropdown,
.mobile-header-dropdown {
  .btn-imo {
    margin-left: 0;
  }
  button {
    display: flex;
    align-items: center;
  }
  button i {
    color: var(--imo-grey-700);
    margin-right: 8px;
  }
  button.info-red,
  button.info-red i {
    color: var(--imo-red);
  }
}
.fixed-grid-loader {
  position: absolute;
  z-index: 11;
  width: 100%;
  background: var(--white);
  inset: 0;
}
.grid-content-shadow {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
  height: 76px;
  pointer-events: none;
  box-shadow: 0px 5px 13px 0px var(--imo-main-shadow);
  @media (max-width: 768px) {
    height: 92px;
  }
}
.free-mode .grid-type-2 .grid-content-shadow {
  height: 127px;
}
[data-theme='light'] .grid-content-shadow {
  box-shadow: 0 5px 13px 0 #ececec;
}
.slick-cell.cell-PF {
  padding: 1px 2px;
  & span {
    width: 100%;
  }
}
[data-theme='dark'] {
  .property-dashboard,
  .question-dashboard {
    background-color: var(--white);
  }
}

.ui-state-default.slick-headerrow-column .basic-single.small {
  .select__control {
    min-width: unset;
  }
  .select__single-value {
    position: unset !important;
    transform: unset !important;
    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .select__value-container {
    position: unset !important;
    padding-right: 1px;
  }
  .select__input-container {
    position: absolute;
    margin: 0 !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
  .select__indicators {
    position: relative;
    left: -15px;
    width: 15px;
  }
}
