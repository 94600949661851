// Variable overrides

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1280px,
  mac: 1440px,
  xl: 1901px,
);
$spacer: 1rem;
$spacers: (
  15: (
    $spacer * 0.35,
  ),
  10: (
    $spacer * 0.63,
  ),
  20: (
    $spacer * 1.25,
  ),
  30: (
    $spacer * 1.875,
  ),
  45: (
    $spacer * 1.75,
  ),
  55: (
    $spacer * 3.5,
  ),
);

$imo-white: #ffffff;
$imo-off-white: #fcfcfc;
$imo-orange: #ee7d00;
$imo-red: #ff0056;
$imo-green: green;
$imo-light-blue: #3fa2db;
$imo-blue: #3b87ba;

$imo-grey-50: #fafafa;
$imo-grey-100: #f6f6fa;

$imo-grey-300: #edeef6;
$imo-grey-400: #e9ecef;
$imo-grey-500: #ececec;
$imo-grey: #cccccc;
$imo-grey-600: #dee2e6;
$imo-grey-700: #797979;
$imo-grey-900: #35393c;
$imo-black: #16181b;

$imo-grey-shadow: #cccccc;
$imo-grey-secondary: #eee;
$imo-grey-secondary-100: #aaa;
$imo-grey-secondary-200: #999;
$imo-grey-secondary-500: #606060;
$imo-grey-secondary-600: #535353;

$imo-table-border: #dee2e6;
$imo-mobile-header-shadow: #ddd;
$imo-main-shadow: rgba(204, 204, 204, 0.5);

$imo-menu-text-hover: rgba(0, 0, 0, 0.7);
$imo-menu-text-active: rgba(0, 0, 0, 0.9);

$colors: (
  'white': $imo-white,
  'imo-off-white': $imo-off-white,
  'imo-orange': $imo-orange,
  'imo-red': $imo-red,
  'imo-green': $imo-green,
  'imo-blue': $imo-light-blue,
  'imo-grey-50': $imo-grey-50,
  'imo-grey-100': $imo-grey-100,
  'imo-grey-300': $imo-grey-300,
  'imo-grey-400': $imo-grey-400,
  'imo-grey-500': $imo-grey-500,
  'imo-grey': $imo-grey,
  'imo-grey-600': $imo-grey-600,
  'imo-grey-700': $imo-grey-700,
  'imo-grey-900': $imo-grey-900,
  'imo-black': $imo-black,
  'imo-grey-shadow': $imo-grey-shadow,
  'imo-main-shadow': $imo-main-shadow,
  'imo-mobile-header-shadow': $imo-mobile-header-shadow,
  'imo-table-border': $imo-table-border,
  'imo-grey-secondary': $imo-grey-secondary,
  'imo-grey-secondary-100': $imo-grey-secondary-100,
  'imo-grey-secondary-200': $imo-grey-secondary-200,
  'imo-grey-secondary-500': $imo-grey-secondary-500,
  'imo-grey-secondary-600': $imo-grey-secondary-600,
  'imo-menu-text-hover': $imo-menu-text-hover,
  'imo-menu-text-active': $imo-menu-text-active,
);

$theme-colors: (
  'imo-primary': $imo-orange,
  'imo-secondary': $imo-blue,
  'imo-danger': $imo-red,
  'imo-info': $imo-light-blue,
  'imo-grey': $imo-grey,
  'imo-light': $imo-grey-100,
  'imo-grey-300': $imo-grey-300,
  'imo-grey-500': $imo-grey-500,
  'imo-grey-600': $imo-grey-600,
  'imo-grey-700': $imo-grey-700,
  'imo-dark': $imo-grey-900,
);

$imo-white-dark: #1d1f22;
$imo-off-white-dark: #25282a;
$imo-orange-dark: #ee7d00;
$imo-red-dark: #ff0056;
$imo-light-blue-dark: #3fa2db;
$imo-blue-dark: #3b87ba;

$imo-grey-50-dark: #1d1f22;
$imo-grey-100-dark: #35393c;
$imo-grey-300-dark: #1d1f22;
$imo-grey-400-dark: #797575;
$imo-grey-500-dark: #373b40;
$imo-grey-dark: #797575;
$imo-grey-600-dark: #1d1f22;
$imo-grey-700-dark: #bababa;
$imo-grey-900-dark: #d2d2d2;
$imo-black-dark: #d2d2d2;
$imo-grey-shadow-dark: #1d1f21;

$imo-grey-secondary-dark: #373b40;
$imo-grey-secondary-100-dark: #ccc;
$imo-grey-secondary-200-dark: #ccc;
$imo-grey-secondary-500-dark: #fcfcfc;
$imo-grey-secondary-600-dark: #fcfcfc;

$imo-main-shadow-dark: #313132;
$imo-table-border-dark: #4c5053;

$imo-menu-text-hover-dark: #dee2e6;
$imo-menu-text-active-dark: #d2d2d2;

$colors-dark: (
  'white': $imo-white-dark,
  'imo-off-white': $imo-off-white-dark,
  'imo-orange': $imo-orange-dark,
  'imo-red': $imo-red-dark,
  'imo-blue': $imo-light-blue-dark,
  'imo-grey-50': $imo-grey-50-dark,
  'imo-grey-100': $imo-grey-100-dark,
  'imo-grey-300': $imo-grey-300-dark,
  'imo-grey-400': $imo-grey-400-dark,
  'imo-grey-500': $imo-grey-500-dark,
  'imo-grey': $imo-grey-dark,
  'imo-grey-600': $imo-grey-600-dark,
  'imo-grey-700': $imo-grey-700-dark,
  'imo-grey-900': $imo-grey-900-dark,
  'imo-black': $imo-black-dark,
  'imo-grey-shadow': $imo-grey-shadow-dark,
  'imo-main-shadow': $imo-main-shadow-dark,
  'imo-mobile-header-shadow': $imo-main-shadow-dark,
  'imo-table-border': $imo-table-border-dark,
  'imo-grey-secondary': $imo-grey-secondary-dark,
  'imo-grey-secondary-100': $imo-grey-secondary-100-dark,
  'imo-grey-secondary-200': $imo-grey-secondary-200-dark,
  'imo-grey-secondary-500': $imo-grey-secondary-500-dark,
  'imo-grey-secondary-600': $imo-grey-secondary-600-dark,
  'imo-menu-text-hover': $imo-menu-text-hover-dark,
  'imo-menu-text-active': $imo-menu-text-active-dark,
);

$theme-colors-dark: (
  'imo-primary': $imo-orange-dark,
  'imo-secondary': $imo-blue-dark,
  'imo-danger': $imo-red-dark,
  'imo-info': $imo-light-blue-dark,
  'imo-grey': $imo-grey-dark,
  'imo-light': $imo-grey-100-dark,
  'imo-grey-300': $imo-grey-300-dark,
  'imo-grey-500': $imo-grey-500-dark,
  'imo-grey-600': $imo-grey-600-dark,
  'imo-grey-700': $imo-grey-700-dark,
  'imo-dark': $imo-grey-900-dark,
);

$imo-base-font: 'Lato', sans-serif;

$icon-path: '../assets/fonts';

// Buttons + Forms
$input-btn-font-family: $imo-base-font;

// Dropdown
$dropdown-border-radius: 16px;
$dropdown-border-color: transparent;
$dropdown-bg: var(--white);
$dropdown-divider-bg: var(--imo-grey-400);
//  Buttons
$btn-padding-y: 0.9rem;
$btn-padding-x: 1.4rem;
$btn-font-size: 12px;

$btn-line-height: 1;

$btn-border-radius: 20px;
$btn-border-width: 2px;
$input-btn-focus-box-shadow: none;

$input-box-shadow: none;
$input-focus-box-shadow: none;
// Checkbox
$custom-select-border-radius: 4px;

// $custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-size: 1.57rem;
$custom-control-indicator-border-color: var(--imo-grey);

$custom-control-indicator-checked-color: var(--white);
$custom-control-indicator-checked-bg: var(--imo-orange);
$custom-control-indicator-checked-disabled-bg: var(--imo-orange);

$custom-control-indicator-checked-box-shadow: none;

$custom-control-indicator-focus-box-shadow: none;
$custom-control-indicator-focus-border-color: var(--imo-grey-900);
$custom-control-indicator-hover-border-color: var(--imo-grey-900);

$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-active-bg: var(--white);
$custom-control-indicator-active-border-color: var(--imo-grey-700);

$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='4' fill='white'/></svg>");
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='9' viewBox='5.5 8 14 9'><path id='check' fill='#FFFFFF' d='M19.207,8.277c0.36,0.342,0.389,0.879,0.084,1.251l-0.084,0.089l-7.5,7.107	c-0.15,0.142-0.336,0.229-0.531,0.262L11.059,17h-0.118c-0.197-0.011-0.39-0.076-0.554-0.197l-0.094-0.078l-4.5-4.265 c-0.391-0.37-0.391-0.97,0-1.34c0.36-0.341,0.928-0.368,1.32-0.079l0.094,0.079L11,14.713l6.793-6.436 C18.184,7.908,18.816,7.908,19.207,8.277z'/></svg>");

// form input
$input-height: 50px;

$input-padding-x: 1.25rem;
$input-font-size: 13px;

$input-color: var(--imo-grey-900);
$input-hover: var(--imo-grey-900);

$input-border-color: var(--imo-grey);
$input-focus-border-color: var(--imo-blue);

$input-focus-box-shadow: 0 0 3px 0 var(--imo-blue);

$input-disabled-bg: var(--imo-grey-400);

// Cards
$card-border-width: 0;
$card-border-radius: 6px;
$card-border-color: transparent;
$card-cap-bg: var(--white);

// Tables

$table-cell-padding: 0.75rem !default;
$table-cell-padding-sm: 0.3rem !default;

$table-color: var(--imo-grey-900);
$table-bg: null !default;
$table-accent-bg: var(--imo-off-white);
$table-hover-color: $table-color !default;

$table-border-width: 1px;
// Modals
$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: 16px;
$modal-backdrop-opacity: 0.3;
$modal-backdrop-bg: var(--imo-grey-900);
$modal-content-bg: var(--imo-off-white);
$modal-header-padding-y: 1.75rem;
$modal-header-padding-x: 1.55rem;
$modal-footer-border-color: var(--imo-grey-300);
$modal-footer-border-width: 1px;

// tabs
$nav-tabs-border-color: transparent;

$nav-tabs-border-radius: 6px;
$nav-tabs-link-hover-border-color: transparent;

$nav-tabs-link-active-bg: var(--white);
$nav-tabs-link-active-border-color: transparent;

$navbar-light-active-color: var(--imo-menu-text-active);
$navbar-light-hover-color: var(--imo-menu-text-hover);
