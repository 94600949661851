.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50em;
  width: 36px;
  height: 36px;
  font-size: 14.4px;
  overflow: hidden;
}

.avatar-img {
  width: 100%;
  height: 100%;
}

.avatar-img.horizontal {
  width: auto;
  height: 100%;
}

.avatar-img.vertical {
  width: 100%;
  height: auto;
}

.badge-\#0090D5 {
  background-color: #0090d5;
}

.badge-\#008fd5 {
  background-color: #008fd5;
}

.badge-\#b13952 {
  background-color: #b13952;
}
.mobile-avatar-container {
  position: absolute;
  right: 10px;
  z-index: 1111;
  align-items: center;
}
