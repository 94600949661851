div.select__control {
  &:hover {
    border-color: var(--imo-grey-900);
  }
  &.select__control--is-focused {
    border-color: #3b87ba;
    box-shadow: 0 0 3px 0 #3b87ba;
  }
}
.select-invalid .select__control {
  border-color: #ff0056 !important;
  &.select__control--is-focused {
    box-shadow: 0 0 3px 0 #ff0056 !important;
  }
}
.select__control {
  border-radius: 4px !important;
  border: solid 1px var(--imo-grey);
  min-height: 50px;
  min-width: 100px;
  text-transform: none;
  z-index: 3;
  position: relative;

  &--is-focused {
    box-shadow: none;
  }

  .select__placeholder {
    color: var(--imo-grey);
    font-size: 14px;
  }

  .small & {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    height: 30px;
    min-height: 30px;
    align-items: flex-start;

    .select__value-container {
      height: 100%;
      padding: 2px 8px;
    }

    .select__single-value {
      padding-left: 6px;
    }

    .select__placeholder {
      color: var(--imo-grey);
      padding-left: 6px;
    }

    .select__indicators {
      height: 30px;
    }
  }

  .card-header & {
    min-width: 150px;
  }

  .select__single-value {
    font-size: 13px !important;
    color: var(--imo-grey-900) !important;
    font-weight: normal !important;
    display: block !important;

    &:before {
      display: inline-block !important;
      margin-bottom: 1px;
    }
  }

  .select__value-container {
    padding: 2px 20px;
  }

  .select__indicator-separator {
    width: 0;
  }
  [data-theme='dark'] & .select__multi-value {
    background-color: var(--imo-off-white) !important;
  }
  .select__multi-value {
    background-color: var(--imo-grey-300) !important;
    padding: 5px;

    &__label {
      color: var(--imo-grey-900) !important;
    }

    &__remove {
      color: var(--imo-grey-700) !important;
      cursor: pointer;
      width: 12px;
      height: 12px;
      background-image: url(../assets/img/close.svg);
      background-size: 100%;
      background-repeat: no-repeat;
      margin: 8px;

      svg {
        display: none;
      }

      &:hover {
        background-color: transparent;
        opacity: 0.8;
      }
    }
  }
  .select__dropdown-indicator {
    background-image: url(../assets/img/dropdown-indicator.svg);
    background-position: center;
    background-repeat: no-repeat;
    svg {
      visibility: hidden;
    }
  }
}

.currency {
  .select__control {
    min-width: auto;
    height: 50px;

    .select__value-container {
      padding: 2px 10px;
    }

    .select__indicator {
      padding: 8px 4px;
    }
  }
}

.select__menu {
  z-index: 110 !important;
  position: relative;
  box-shadow: 0 0 7px 0 rgba(204, 204, 204, 0.85) !important;

  &-list {
    @include scrollbars(0.5em, var(--imo-grey), white);
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
}
[data-theme='dark'] .select__menu {
  box-shadow: 0 0 7px 0 var(--imo-main-shadow) !important;
}

.select__option {
  display: block !important;
  font-size: 13px !important;
  font-style: normal !important;
  padding: 4.5px 20px !important;
  letter-spacing: normal !important;
  color: var(--imo-grey-900) !important;

  // trunc
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  &:before {
    display: inline-block !important;
    margin-bottom: 1px;
  }

  &--is-selected {
    background-color: var(--imo-grey-100) !important;
  }

  &--is-focused {
    background-color: var(--imo-grey-100) !important;

    .custom-control-label {
      &:before {
        border-color: var(--imo-grey-700);
      }
    }
  }

  &--is-disabled {
    color: var(--imo-grey) !important;

    .custom-control-label {
      color: var(--imo-grey);

      &:before {
        border-color: var(--imo-grey-500);
      }
    }
  }
}

.h50 {
  .select__control {
    min-height: 50px !important;
  }
}

.table_select {
  &.select__menu-portal {
    .table-menulist {
      padding: 0 !important;
      margin: 0 !important;
      .select__option {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
}
// większa przerwa między scrollbarem a borderem w selectach
.select__menu {
  .ps__rail-y {
    width: 19px;
  }
  .ps__thumb-y {
    right: 4px;
  }
}
div.select__value-container {
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  .select__single-value {
    grid-area: unset;
    color: rgb(51, 51, 51);
    margin-left: 0;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
  }
  .select__placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
  }
}
.react-datepicker div.select__value-container .select__single-value {
  position: initial;
  transform: initial;
  top: initial;
  max-width: initial;
}
.search-field-normal-height {
  .select__control {
    min-height: 50px;
  }
  .select__value-container {
    height: 48px;
    padding: 0 20px;
    & > div {
      width: 100%;
      height: 48px;
      min-height: 48px;
      & > span {
        display: flex;
        width: 100%;
        height: 48px;
        & > .select__input-container {
          width: 100%;
          height: 100%;
          margin: 0;
          border: 0;
          padding: 0;
          position: relative;
          & > input {
            position: absolute;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
div.select__menu-portal {
  z-index: 11111;
}

div.select__control {
  background-color: var(--white);
  border-color: var(--imo-grey);
}
[data-theme='dark'] {
  div.select__control {
    background-color: var(--imo-grey-100);
    border-color: transparent;
    &:hover {
      border-color: var(--imo-grey-900);
    }
    &.select__control--is-focused {
      border-color: #3b87ba;
    }
  }
  .react-datepicker div.select__control {
    background-color: var(--white);
    border-color: var(--imo-grey);
    &:hover {
      border-color: var(--imo-grey-900);
    }
    &.select__control--is-focused {
      border-color: #3b87ba;
    }
  }
}
div.select__menu {
  background-color: var(--white);
  overflow: hidden;
}
div.select--is-disabled {
  border-radius: 0.25rem;
}
div.select__input-container {
  color: var(--imo-grey-900);
}
