@charset "UTF-8";

@font-face {
  font-family: 'imo';
  src: url('../assets/fonts/imo.eot');
  src: url('../assets/fonts/imo.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/imo.woff') format('woff'),
    url('../assets/fonts/imo.ttf') format('truetype'), url('../assets/fonts/imo.svg#imo') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon[class*='icon-'] {
  font-family: 'imo';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.25rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
  font-family: 'imo' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'imo' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: '\64';
}
.icon-full-screen:before {
  content: '\65';
}
.icon-full-screen-off:before {
  content: '\66';
}
.icon-help:before {
  content: '\67';
}
.icon-calculator:before {
  content: '\68';
}
.icon-calendar:before {
  content: '\69';
}
.icon-new:before {
  content: '\6a';
}
.icon-offers:before {
  content: '\6b';
}
.icon-phone:before {
  content: '\6c';
}
.icon-change:before {
  content: '\6d';
}
.icon-point:before {
  content: '\6e';
}
.icon-print:before {
  content: '\6f';
}
.icon-pokaz-zaznaczone:before {
  content: '\70';
}
.icon-data:before {
  content: '\71';
}
.icon-eye:before {
  content: '\72';
}
.icon-refferals:before {
  content: '\73';
}
.icon-edit:before {
  content: '\74';
}
.icon-edit2:before {
  content: '\58';
}
.icon-eye-off:before {
  content: '\75';
}
.icon-filters:before {
  content: '\76';
}
.icon-save:before {
  content: '\77';
}
.icon-right:before {
  content: '\78';
}
.icon-left:before {
  content: '\7A';
}
.icon-down:before {
  content: '\7B';
}
.icon-top:before {
  content: '\7C';
}
.icon-reload:before {
  content: '\79';
}
.icon-folder:before {
  content: '\41';
}
.icon-arrow-down:before {
  content: '\42';
}
.icon-send:before {
  content: '\43';
}
.icon-arrow-right:before {
  content: '\44';
}
.icon-arrow-left:before {
  content: '\47';
}
.icon-arrow-up:before {
  content: '\45';
}
.icon-clear:before {
  content: '\48';
}
.icon-duplicate:before {
  content: '\4b';
}
.icon-list:before {
  content: '\4d';
}
.icon-map:before {
  content: '\4e';
}
.icon-settings:before {
  content: '\4f';
}
.icon-add:before {
  content: '\50';
}
.icon-blur:before {
  content: '\60';
}
.icon-more:before {
  content: '\61';
}
.icon-camera:before {
  content: '\62';
}
.icon-camera2:before {
  content: '\63';
}
.icon-search:before {
  content: '\49';
}
.icon-check:before {
  content: '\55';
}
.icon-share:before {
  content: '\56';
}
.icon-delete:before {
  content: '\54';
}
.icon-delete2:before {
  content: '\57';
}
.icon-back:before {
  content: '\46';
}
.icon-clock:before {
  content: '\4A';
}
.icon-day:before {
  content: '\4C';
}
.icon-download:before {
  content: '\51';
}
.icon-stamp:before {
  content: '\59';
}
.icon-sharpness:before {
  content: '\5A';
}
.icon-brightness:before {
  content: '\5B';
}
.icon-contrast:before {
  content: '\5C';
}
.icon-crop:before {
  content: '\5D';
}
.icon-rotate:before {
  content: '\5E';
}
.icon-saturation:before {
  content: '\5F';
}
.icon-rotate-right:before {
  content: '\7D';
}
.icon-rotate-left:before {
  content: '\7E';
}
.icon-move-right:before {
  content: '\40';
}
.icon-rotate:before {
  content: '\5E';
}
.icon-saturation:before {
  content: '\5F';
}
.icon-left-end:before {
  content: '\52';
}
.icon-right-end:before {
  content: '\53';
}
.icon-move-to-left:before {
  content: '\33';
}
.icon-move-to-right:before {
  content: '\3F';
}
.icon-next:before {
  content: '\30';
}
.icon-download-a:before {
  content: '\31';
}
.icon-download-internet:before {
  content: '\32';
}
.icon-save,
.icon-settings {
  align-self: center;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.6;
  }
}

.filetype {
  width: 24px;
  height: 24px;
  background-image: url('/assets/img/file_types/file.svg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;

  &.icon-pdf {
    background-image: url('/assets/img/file_types/pdf.svg');
  }
  &.icon-doc {
    background-image: url('/assets/img/file_types/doc.svg');
  }
  &.icon-docx {
    background-image: url('/assets/img/file_types/doc.svg');
  }
  &.icon-xls {
    background-image: url('/assets/img/file_types/xls.svg');
  }
  &.icon-xlsx {
    background-image: url('/assets/img/file_types/xls.svg');
  }
  &.icon-jpg {
    background-image: url('/assets/img/file_types/jpg.svg');
  }
  &.icon-png {
    background-image: url('/assets/img/file_types/png.svg');
  }
  &.icon-mp4 {
    background-image: url('/assets/img/file_types/mp4.svg');
  }
  &.icon-xml {
    background-image: url('/assets/img/file_types/xml.svg');
  }
  &.icon-txt {
    background-image: url('/assets/img/file_types/txt.svg');
  }
  &.icon-html {
    background-image: url('/assets/img/file_types/html.svg');
  }
}
