// === Table inputs === //
.slick-headerrow-columns input {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: var(--white);
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Remove select outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus($ignore-warning: true);

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  &:not([disabled]):hover {
    border-color: var(--imo-grey-900);
  }

  &:focus {
    box-shadow: none;
    background-color: var(--white);
  }

  &.is-invalid {
    + label {
      color: var(--imo-red);
    }

    &:focus {
      box-shadow: 0 0 3px 0 var(--imo-red);
    }
  }

  &.small {
    height: 30px;
    padding: 0.375rem 0.65rem;
  }
}

.basic-single {
  &.small {
    .select__control {
      .select__value-container {
        .select__placeholder {
          & + div {
            position: relative;
            top: -3px;
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }
  }
}

.slick-headerrow-column input[type='text'] {
  height: 30px;
  padding: 0.375rem 0.65rem;
}

.slick-header-column input[type='checkbox'],
.slick-row input[type='checkbox'] {
  margin: auto;
  width: auto;
  height: auto;
  padding: 0;
}

// === Bootstrap elements override === //
.custom-control {
  min-height: 1.75rem;
}

.slick-cell .custom-control,
.slick-column-name .custom-control {
  padding-left: 20px;
}
.slick-column-name .custom-control {
  padding-top: 5px;
}

.button-filter {
  width: 20px !important;
  height: 26px !important;
  min-width: 26px !important;
  align-items: baseline !important;
  padding: 8px !important;
  border: 0 !important;
}

.slick-column-name .custom-control-label:before,
.slick-cell .custom-control-label:before {
  left: -17px !important;
  width: 20px !important;
  height: 20px !important;
}
.slick-column-name .custom-control-label:after,
.slick-cell .custom-control-label:after {
  left: -20px !important;
  width: 25px;
  height: 25px;
}

.custom-control-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.6px;
  color: var(--imo-grey-900);

  &:before {
    top: 0.2rem;
  }
  &:after {
    top: 0.2rem;
  }
}

.custom-radio {
  .custom-control-label {
    padding-top: 0.3rem;
    cursor: pointer;
    &:after {
      top: 0.09rem;
    }
  }
}

.custom-switch .custom-control-label::after {
  top: 5px;
  left: -48px;
  width: 18px;
  height: 18px;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: #f9cb99;
  background-color: #f9cb99 !important;
}

label,
.col-form-label {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: var(--imo-grey-700);

  &.to-right {
    float: right;
    margin: 10px 0 0 !important;
    font-weight: normal;
  }
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
}

.row.form-group {
  align-items: center;

  label {
    position: relative;
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
  }

  .form-control {
    width: auto;
    flex-grow: 1;
    margin-right: $grid-gutter-width / 2;
  }

  .col {
    padding-left: 0;
  }
}

.left-border {
  &::before {
    content: '';
    height: 50px;
    width: 1px;
    position: absolute;
    left: -25px;
    top: 8px;
    opacity: 0.2;
    background-color: var(--imo-grey-700);
  }
}
[data-theme='dark'] .form-control {
  background-color: var(--imo-grey-100);
  &:focus {
    background-color: var(--imo-grey-100);
  }
}
.form-control {
  background-color: var(--white);
  &:focus {
    background-color: var(--white);
  }
  &::placeholder {
    color: var(--imo-grey);
  }

  &.is-invalid {
    + label {
      color: var(--imo-red);
    }

    &:focus {
      box-shadow: 0 0 3px 0 var(--imo-red);
    }
  }

  &.small {
    height: 30px;
  }

  .react-date-picker__wrapper {
    border: 0;

    .react-date-picker__inputGroup {
      padding: 0;
    }

    input {
      &:focus-visible {
        outline: none;
      }
    }
    & + span {
      .react-date-picker__calendar {
        z-index: 9;
      }
      .react-calendar {
        border-radius: 16px;
        box-shadow: 5px 5px 30px var(--imo-grey);
        border: 0;
        overflow: hidden;
      }
    }
    .react-date-picker__button {
      outline: 0;
      height: 36px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 2px;

      &.react-date-picker__clear-button {
        svg {
          max-width: 15px;
          margin: 3px 7px 0 0;
          opacity: 0.7;
        }
      }
    }
  }

  .react-calendar {
    .custom-buttons {
      display: flex;
      width: 100%;
      flex-flow: row wrap;

      a {
        width: 50%;
        padding: 15px 0;
        text-align: center;
      }
    }
  }
}

.react-datepicker-wrapper {
  display: block !important;

  .form-control {
    background-image: url(../assets/img/icon-calendar.svg);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: right 15px bottom 51%;
    cursor: pointer;
    &:disabled {
      pointer-events: none;
    }
    &::placeholder {
      color: var(--imo-grey-900);
    }
  }
  .form-control.timepicker {
    background-image: url(../assets/img/icon-clock.svg);
  }
  .react-datepicker__close-icon {
    right: 34px;
    &::after {
      font-size: 26px;
      background: none;
      color: var(--imo-grey-700);
    }
    &:not([disabled]):hover {
      &::after {
        color: var(--imo-grey-900);
      }
    }
  }
}
[data-theme='dark'] .react-datepicker {
  box-shadow: 5px 5px 30px var(--imo-main-shadow);
}
.react-datepicker {
  max-width: 242px;
  display: flex !important;
  flex-flow: row wrap;
  justify-content: space-around;
  font-family: $imo-base-font !important;
  font-size: 14px !important;
  border-radius: 16px !important;
  overflow: hidden;
  box-shadow: 5px 5px 30px var(--imo-grey);
  border: 0 !important;
  .react-datepicker__time-list-item--selected {
    background-color: var(--imo-orange) !important;
  }
  .react-datepicker__clear-button {
    background: transparent;
    border-top: 0;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 0 0 12px;
    font-size: 12px;
    font-weight: normal;
    color: var(--imo-blue);
    &:not([disabled]):hover {
      opacity: 0.7;
    }
  }
  .react-datepicker__today-button {
    background: transparent;
    border-top: 0;
    padding: 0 0 12px;
    font-size: 12px;
    clear: none;
    font-weight: normal;
    color: var(--imo-blue);
    &:not([disabled]):hover {
      opacity: 0.7;
    }
  }
  .react-datepicker__day--outside-month {
    opacity: 0.15;
  }
  .react-datepicker__day--weekend {
    color: var(--imo-red) !important;
  }
  .react-datepicker__day--selected {
    background-color: var(--imo-orange) !important;
    color: #fff !important;
    &:not([disabled]):hover {
      background-color: var(--imo-orange) !important;
    }
    &.react-datepicker__day--weekend {
      color: var(--white);
    }
  }
  .react-datepicker__day--weekend {
    &.react-datepicker__day--in-range {
      color: var(--white);
    }
    &.react-datepicker__day--in-selecting-range {
      color: var(--white);
    }
  }
  .react-datepicker__day--keyboard-selected {
    background-color: var(--white);
    color: inherit;
  }
  .react-datepicker__day {
    border-radius: 50% !important;
    font-weight: normal;
    &:not([disabled]):hover {
      background-color: #f0f0f0;
    }
  }
  .react-datepicker__header {
    border-bottom: 0;
    background-color: transparent;
    margin-top: 1px;
    select {
      border: 0;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      cursor: pointer;
      outline-style: none;
      background-color: var(--white);
    }
  }
  .react-datepicker__navigation--previous {
    border: 0;
    background-image: url(../assets/img/icon-left.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
  }
  .react-datepicker__navigation--next {
    border: 0;
    background-image: url(../assets/img/icon-right.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
  }
  .react-datepicker__current-month {
    display: none;
  }
  .react-datepicker__day-name {
    margin-top: 10px;
  }
  .react-datepicker__week {
    &:last-child {
      display: none;
    }
  }
  .react-datepicker-time__header {
    font-size: 0px;
    &::after {
      content: 'Godzina';
      font-size: 14px;
    }
  }
  .react-datepicker__time-list {
    @include scrollbars(1em, var(--imo-grey), white);
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      border: 4px solid var(--white);
    }
    &::-webkit-scrollbar {
      width: 14px;
    }
  }
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__month-select,
.react-datepicker__year-select {
  color: var(--imo-grey-900) !important;
}
.react-datepicker-popper {
  z-index: 1111 !important;
}
[data-theme='dark'] {
  input.form-control,
  textarea.form-control {
    &:not(.is-invalid) {
      border-color: transparent;
    }
    background-color: var(--imo-grey-100);
    &:focus {
      background-color: var(--imo-grey-100);
    }
  }
  .highlightWithinTextarea {
    background-color: var(--imo-grey-100);
    &:focus {
      background-color: var(--imo-grey-100);
    }
    border-radius: 0.25rem;
  }
}

input.form-control,
textarea.form-control {
  &:not([disabled]):hover {
    border-color: var(--imo-grey-900);
  }
  &:focus {
    border-color: var(--imo-blue) !important;
  }
  &.is-invalid:focus {
    border-color: var(--imo-red) !important;
  }
  &.is-invalid:hover {
    border-color: var(--imo-red) !important;
  }
}
textarea.form-control {
  padding: 0.95rem 1.25rem;
}

.form-group {
  margin-bottom: 0.5rem;

  &.add-separator {
    position: relative;

    &:after {
      content: '-';
      position: absolute;
      top: 11px;
      right: -11px;
    }
    &.date-picker {
      &:after {
        top: 36px;
      }
    }
  }

  &.add-unit {
    position: relative;

    input {
      padding-right: 35px;
    }

    small {
      position: absolute;
      color: var(--imo-grey-secondary);
      right: 10px;
      top: 16px;
    }
  }
}

[data-theme='dark'] .form-group.add-unit small {
  color: #797575;
}

.form-control:disabled {
  opacity: 0.4;
  &:not([disabled]):hover {
    border-color: var(--imo-grey);
  }
}
.select--is-disabled {
  opacity: 0.4;
  .select__control {
    border-color: $input-border-color;
    background-color: var(--imo-grey-400);
  }
  background-color: var(--imo-grey-400);
  cursor: default !important;
  &:not([disabled]):hover {
    border: 1px solid var(--imo-grey);
  }
}
.select__control,
.select__option,
.select__indicators {
  cursor: pointer !important;
}
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  opacity: 0.4;
  cursor: default;
}

.custom-input-range {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &:not([disabled]):hover {
    opacity: 1;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--imo-grey-secondary);
    cursor: pointer;
    border-radius: 50%;
  }
  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--imo-grey-secondary);
    cursor: pointer;
    border-radius: 50%;
  }
}
textarea.form-control:disabled {
  background-color: var(--imo-grey-400) !important;
}
.tox.tox-tinymce--disabled {
  & .tox-edit-area__iframe {
    opacity: 0.4;
    background-color: var(--imo-grey-400);
  }
}
.form-group input:not([type='checkbox']):disabled {
  opacity: 0.4;
  background-color: var(--imo-grey-400);
}
.form-group input:not([type='checkbox']):disabled + button {
  opacity: 0.4;
  background-color: var(--imo-grey-400);
  pointer-events: none;
}
.editable-div.is-disabled {
  opacity: 0.4;
}
.editable-div > div {
  background-color: #fff;
  transition: 0.2s;
}
.editable-div > div:hover {
  border: 1px solid var(--imo-grey-900);
}
.editable-div.is-disabled > div {
  background-color: var(--imo-grey-400);
}

[data-theme='dark'] {
  .form-group input:not([type='checkbox']):disabled,
  .form-group input:not([type='checkbox']):disabled + button {
    background-color: var(--imo-grey-100);
  }
  textarea.form-control:disabled {
    background-color: var(--imo-grey-100) !important;
  }
  .highlightWithinTextarea.is-disabled {
    opacity: 0.4;
  }
  .is-disabled.is-disabled {
    opacity: 0.4;
  }
  .editable-div > div {
    border:1px solid transparent;
  }
  .editable-div > div:hover {
    border: 1px solid var(--imo-grey-900);
  }
  .editable-div > div .highlight {
    background-color: #000;
  }
  .editable-div.is-disabled > div {
    background-color: var(--imo-grey-100);
  }
  .custom-control-input:disabled:not(:checked) + .checkbox-label .checkbox-span {
    background-color: var(--imo-grey-100);
  }
  .tox.tox-tinymce--disabled {
    opacity: 0.4;
    & .tox-edit-area__iframe {
      opacity: 1;
    }
  }
}
.form-control.is-invalid[type='number'] {
  background-image: none;
}
.add-unit.form-group .is-invalid + small {
  right: 30px;
}
.add-unit.form-group input.is-invalid {
  padding-right: 50px;
}
.editor-invalid .tox.tox-tinymce {
  border-color: var(--imo-red);
}
.custom-radio .custom-control-label::after {
  content: '';
  position: absolute;
  transform: translate(55%, 70%);
  width: 12px;
  height: 12px;
  background: var(--white);
  border-radius: 50%;
}
.custom-control.custom-radio * {
  cursor: pointer;
}
@media (min-width: 768px), (min-height: 600px) {
  .header-user-menu .dropdown-menu {
    min-width: 14rem;
  }
}
.header-user-menu .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.custom-dropdown-item {
  display: grid;
  padding: 0.25rem 1.5rem;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: normal;
  color: var(--imo-grey-900);
  & > span {
    width: fit-content;
    max-width: 7rem;
  }
}
.custom-switch-container {
  padding: 0;

  .custom-switch {
    padding-left: 0;
    margin: 0;
    .custom-control-label {
      width: 100%;
      padding: 0.25rem 1.5rem;
      line-height: 1.75;
      font-size: 13px;
      letter-spacing: normal;
      cursor: pointer;
      color: var(--imo-grey-900);
      &:hover {
        background-color: var(--imo-grey-100);
        color: var(--imo-black);
      }
      &:after {
        top: 5px;
        left: unset;
        right: calc(1.5rem + 22px);
      }
      &:before {
        top: 2px;
        left: unset;
        right: 1.5rem;
      }
    }
  }
}
div.grid-type-chose-container {
  transform: scale(0.6);
  margin: -17px 0 -12px -60px;
  flex-direction: row;
  flex-wrap: nowrap;
}
.grid-type-chose {
  width: 100px;
  height: 60px;
  margin: 5px 0 13px 0;

  input[type='radio'] {
    width: 100px;
    height: 80px;
  }
  .custom-control {
    padding-left: 0;
  }
  .custom-control-input ~ .custom-control-label {
    transition: none;
  }
  .custom-control-input ~ .custom-control-label:before {
    transition: transform 0.2s ease !important;
    width: 100px;
    height: 80px;
    border-radius: 0;
    border: 0;
    transition: 0.2s;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: 90px;
  }
  .custom-control-input ~ .custom-control-label:after {
    display: none;
  }
  .custom-control-input:checked ~ .custom-control-label:before {
    background-color: transparent;
  }
  .custom-radio:hover {
    z-index: 1;
  }
  .custom-control-input:hover ~ .custom-control-label {
    z-index: 1;
    &:before {
      transform: scale(1.1);
      z-index: 1;
    }
  }
}

.grid-type-chose:nth-child(1) .custom-control-input ~ .custom-control-label:before {
  background-image: url(../assets/img/imo-grid1.svg);
}
.grid-type-chose:nth-child(2) .custom-control-input ~ .custom-control-label:before {
  background-image: url(../assets/img/imo-grid2.svg);
}
.grid-type-chose:nth-child(3) .custom-control-input ~ .custom-control-label:before {
  background-image: url(../assets/img/imo-grid3.svg);
}

.grid-type-chose:nth-child(1) {
  .custom-radio.checked .custom-control-label:before,
  .custom-control-input:checked ~ .custom-control-label:before {
    background-image: url(../assets/img/imo-grid1_hover.svg);
  }
}
.grid-type-chose:nth-child(2) {
  .custom-radio.checked .custom-control-label:before,
  .custom-control-input:checked ~ .custom-control-label:before {
    background-image: url(../assets/img/imo-grid2_hover.svg);
  }
}
.grid-type-chose:nth-child(3) {
  .custom-radio.checked .custom-control-label:before,
  .custom-control-input:checked ~ .custom-control-label:before {
    background-image: url(../assets/img/imo-grid3_hover.svg);
  }
}

.custom-control-label::before {
  background-color: var(--white);
}
.form-control[readonly]:focus {
  background-color: var(--imo-grey-400);
}
