.btn {
  &:focus,
  &:active {
    box-shadow: none !important;
    outline: none !important;
  }
}

.btn-imo {
  font-family: $imo-base-font;
  display: flex;
  align-items: center;
  position: relative;

  > svg,
  i {
    margin-right: 0.5rem;
  }

  &.btn-icon {
    > svg,
    i {
      margin-right: 0;
    }
  }
}
.btn-outline-imo-secondary,
.btn-outline-imo-danger,
.btn-outline-imo-primary {
  font-weight: bold;
  padding: 0.775rem 1.437rem;
}

.btn-icon {
  padding: 0;
  border-radius: 50%;
  height: 2.5rem;
  min-width: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  font-size: 0.8rem;

  &.small {
    min-width: 1rem;

    .icon {
      font-size: 14px;
    }
  }

  &--first {
    min-width: 1.5rem;
    height: auto;
  }
}

.btn-imo-light {
  box-shadow: 0 3px 10px 0 var(--imo-grey-shadow);
  color: var(--imo-grey-700);
  border-color: var(--imo-grey-100);
  background-color: var(--imo-grey-100);
  &:hover {
    box-shadow: 0 3px 10px 0 rgba(63, 162, 219, 0.55);
    background-color: var(--imo-grey-300);
    border-color: var(--imo-grey-300);
    color: var(--imo-grey-700);
  }
}

.btn-imo-primary {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #ee7d00 0%, #ff4200 50%, #ee7d00 100%);
  border: none;
  background-repeat: no-repeat;
  background-size: auto 200%;
  background-position: 0 0;
  box-shadow: none;

  transition: 0.25s;
  letter-spacing: 0.6px;
  &:focus,
  &:active {
    box-shadow: 0 0 0 3px rgba(#fd4c00, 0.38) !important;
  }
  &:hover,
  &:active {
    background-position: 0% 100%;
    border-color: transparent;
    &::before {
      opacity: 0;
      transform: translate(0, 0);
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: all 0.5s;
    opacity: 1;
    transform: translate(-105%, 0);
    background-color: rgba(255, 255, 255, 0.8);
  }

  i[class*='icon-add'] {
    font-size: 12px;
  }
}

.btn-thirty-imo-grey-700 {
  &:active {
    .icon {
      color: var(--imo-light-blue);
    }
  }
}
//
// Ghost button
//

@each $color, $value in $theme-colors {
  .btn-ghost-#{$color} {
    @include button-ghost-variant($value);
  }
}

//
// Thirty button
//

@each $color, $value in $theme-colors {
  .btn-thirty-#{$color} {
    @include button-thirty-variant($value);
  }
}

.filter-button {
  justify-content: center;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 14px;
  align-self: center;
}

.add-button-tooltip .disabled {
  pointer-events: none;
}
.btn-thirty-imo-grey-700 {
  color: var(--imo-grey-700);
}
.btn-thirty-imo-grey-700:hover,
.btn-thirty-imo-grey-700:focus {
  color: var(--imo-grey-secondary-500);
}
a.header-nav-link.text-imo-grey-700 {
  color: var(--imo-grey-700) !important;
}
a.header-nav-link.text-imo-grey-700:hover,
a.header-nav-link.text-imo-grey-700:focus {
  color: var(--imo-grey-secondary-600) !important;
}
.grid-box__controll svg path {
  fill: var(--imo-grey-900);
}
[data-theme='dark'] .btn-imo-light {
  &:focus {
    background-color: var(--imo-grey-300);
    border-color: var(--imo-grey-300);
    color: var(--imo-grey-700);
  }
}
[data-theme='dark'] {
  .btn-imo-light:not(:disabled):not(.disabled):active,
  .btn-imo-light:not(:disabled):not(.disabled).active,
  .show > .btn-imo-light.dropdown-toggle {
    background-color: var(--imo-grey-300);
    border-color: var(--imo-grey-300);
    color: var(--imo-grey-700);
  }
  .btn-thirty-imo-secondary:hover {
    color: #53baff;
  }
}
