$cell-min-width: 120px;
//$table-font-size: 0.75rem;
$table-font-size: 12px;

// === IMO Table structure === //
.slick-header-columns {
  display: flex;
}

.slick-viewport {
  @include media-breakpoint-down(md) {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 270px);
  }
  @include media-breakpoint-down(sm) {
    height: calc(100vh - 260px);
  }
}

.ui-widget {
  // select prevent
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slick-header-column {
  border: 1px solid var(--imo-grey-600);
  border-top: 1px solid var(--imo-grey-600);
  border-bottom: 0;
  color: var(--imo-grey-700);
  border-right: none;
  min-width: $cell-min-width;
  vertical-align: bottom;
  padding-bottom: 0;
  font-size: $table-font-size;
  padding: 9px 6px;
  min-height: 34px;

  @include media-breakpoint-up(md) {
    padding: 10px 12px 5px;
  }

  .slick-resizable-handle {
    display: inline-block;
    background: transparent;
    width: 7px;
    height: 200%;
    max-height: 80px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
  }
}

.slick-header-column-first {
  padding: 8px 10px;
  border-left: 0;

  @include media-breakpoint-up(md) {
    padding: 0 12px 0;
    height: 34px;
  }
}

.slick-header {
  top: 0;
  left: -1px;
  right: 0;
  background: var(--white);
  z-index: 2;

  &.mobile-fixed {
    position: fixed;
    left: 1px;
    z-index: 2000;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.slick-header-column {
  cursor: pointer;
}

.slick-headerrow {
  left: -1px;
  right: 0;
  top: 38px;
  background: var(--white);
  z-index: 1;
  box-shadow: 0 4px 6px 0 var(--imo-grey-500);

  @include media-breakpoint-up(md) {
    top: 34px;
  }

  &.mobile-fixed {
    position: fixed;
    left: 1px;
    top: 34px;
    z-index: 2000;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.slick-headerrow-alert {
  width: 100%;
  position: sticky;
  top: 76px;

  p {
    margin: 0;
    cursor: pointer;
  }
}

.slick-column-name {
  font-size: 12px;
  color: var(--imo-grey-700);
  letter-spacing: 0.6px;
  font-weight: bold;
  height: 34px;

  .custom-control {
    min-height: 1.5rem;
  }
}

.slick-headerrow-columns {
  display: flex;
}

.slick-headerrow-column {
  border: 1px solid var(--imo-grey-600);
  border-bottom: none;
  border-top: 0;
  border-right: none;
  color: var(--imo-grey-700);
  min-width: $cell-min-width;
  padding-bottom: 5px;
  vertical-align: bottom;
  padding: 0 6px;
  padding-bottom: 11px;
  font-size: $table-font-size;

  @include media-breakpoint-up(md) {
    padding: 0 12px;
    padding-bottom: 0.75rem;
  }

  &:first-of-type {
    border-left: 0;
  }
}
[data-theme='dark'] {
  div[role='columnheader'] input {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      border-color: var(--imo-grey-900);
    }
  }
  .slick-headerrow-column input,
  div[role='columnheader'] input {
    border-color: transparent;
    background-color: var(--imo-grey-100);
    &:focus {
      background-color: var(--imo-grey-100);
      border-color: var(--imo-grey-900);
      outline: 0;
    }
  }
}

.grid-canvas {
  display: flex;
  flex-direction: column;
}

.slick-row {
  display: flex;
  font-size: 12px;
  height: 50px;

  &:hover:not(.active),
  &.hover:not(.active) {
    color: var(--imo-grey-900);
    background-color: var(--imo-grey-300);

    .slick-cell {
      border-color: var(--imo-grey-300);
    }
  }

  &.odd {
    background-color: var(--imo-grey-100);
  }

  &.even {
    background-color: var(--imo-off-white);
  }

  &.active {
    background-color: rgba(238, 125, 0, 0.1);
    color: var(--imo-grey-900);

    .slick-cell {
      border-color: rgba(238, 125, 0, 0.1);
    }
  }
}
[data-theme='dark'] .slick-row.active {
  background-color: rgba(238, 125, 0, 0.3);
  .slick-cell {
    border-color: rgba(238, 125, 0, 0.3);
    box-shadow: 0px -1px 0px 0px rgba(238, 125, 0, 0.5);
  }
}
.slick-cell {
  border: 1px solid var(--imo-grey-600);
  border-bottom: 1px solid var(--imo-grey-600);
  border-top: 0;
  border-right: none;
  color: var(--imo-grey-900);
  min-width: $cell-min-width;
  vertical-align: bottom;
  padding: 0.25rem 12px;
  font-size: $table-font-size;
  display: flex;
  align-items: center;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-of-type {
    border-left: 0;
  }

  &.active {
    background-color: rgba(238, 125, 0, 0.2);
  }

  img {
    min-width: 80px;
    max-height: 40px;
    object-position: center center;
    object-fit: cover;
    width: 100%;
  }

  .icon-camera {
    font-size: 12px;
  }
}

.slick-column-name,
.slick-cell {
  .custom-control-label {
    &:before {
      top: 0.265rem;
      left: -1.57rem;
      display: block;
      width: 1.25rem;
      height: 1.25rem;
    }

    &:after {
      top: 0.165rem;
      left: -1.72rem;
    }
  }
}

#gridWrapP {
  position: relative;
}

#indicator_property {
  display: none;
}

.table-overlay-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 900;
  border-radius: 50%;
  &.show {
    box-shadow: 0 0 30px 0 var(--imo-main-shadow);
  }
  @media (max-width: 768px), (max-height: 600px) {
    position: fixed;
    .dropdown-menu.show {
      transform: unset !important;
      top: unset !important;
      left: unset !important;
      bottom: 1.5rem !important;
      right: 0.9rem !important;
    }
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }

  .btn-icon {
    z-index: 1010;
    position: relative;
    transition: all ease-in-out 0.2s 0.1s;

    @include media-breakpoint-up(lg) {
      height: 3rem;
      min-width: 3rem;
    }
  }
  i {
    transition: ease 0.2s;
    transform: rotate(0);
    margin-left: -1px;
  }

  &.show {
    .btn-icon {
      transition: all ease-in-out 0.2s;
      background-color: var(--white);
      border-color: var(--white);
      box-shadow: none;
      i {
        transform: rotate(90deg);
      }

      &:active,
      &:focus {
        background-color: var(--white);
        border-color: var(--white);
        box-shadow: none;
      }
    }
  }

  .dropdown-menu {
    padding: 16px 8px 12px 0;
  }
  .dropdown-item {
    outline: none;
  }

  .dropdown-menu-content {
    max-height: 300px;
  }

  .dropdown-menu.show {
    min-width: 200px;
    max-width: 230px;
    @include media-breakpoint-up(lg) {
      background-size: 100%;
    }
  }
}

.cell-camera {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.slick-sort-indicator {
  position: absolute;
  top: -2px;
  left: 4px;

  .icon {
    font-size: 8px;
  }

  .up {
    border: none;
    padding: 0;
    color: $orange;
    background-color: transparent;
  }

  .down {
    border: none;
    padding: 0;
    color: $orange;
    background-color: transparent;
  }
}

// === Boostrap Table === //
.table {
  font-size: 12px;

  thead th {
    color: var(--imo-grey-700);
    vertical-align: middle;

    .resizer {
      display: inline-block;
      background: transparent;
      width: 7px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
      touch-action: none;
    }
  }

  th,
  td {
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 52px;
  }
}

.card-body {
  .table {
    margin-top: -1.25rem;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    width: calc(100% + 2.5rem);

    th,
    td {
      &:first-child {
        padding-left: 1.25rem;
      }
    }

    th {
      padding-top: 8px;
      padding-bottom: 8px;

      .form-control {
        margin-top: 6px;
      }
    }

    thead {
      th {
        border-top: 1px solid var(--imo-grey-600);
        // box-shadow: 0 3px 10px 0 var(--imo-grey-500);
        border-bottom: 1px solid var(--imo-grey-600);
      }
    }
  }

  .table-responsive {
    margin-top: -1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    width: calc(100% + 2.5rem);

    .table {
      margin-top: 0;
      margin-left: 1rem;
      margin-right: 1rem;
      width: 100%;
    }
  }
}

.table-bordered {
  border: none !important;

  th,
  td {
    border: none;
    border-left: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    &:first-child {
      border-left: none;
    }
  }
}

.fixed-table {
  width: 100%;
  position: relative;
  table {
    width: 100%;
  }

  thead,
  tbody,
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody {
    display: block;
    overflow: auto;
    table-layout: fixed;
  }
  [role='table'] {
    min-width: auto !important;
  }
}

.first-th-small {
  th:first-child {
    width: 80px;
    text-align: center;
  }
}

.select-table {
  th:first-child {
    width: 50px;
  }
}

.first-th-date {
  th:first-child {
    width: 160px;
  }
}
.table-max-height-100 {
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  & > div {
    height: 100%;
    display: grid;
    border-bottom: none;
    min-width: auto !important;
  }
}
.grid-box.card .slick-cell {
  &:focus-visible {
    outline: none;
  }
}
.table-last-row-md div[role='table'] .scrollbar-container div[role='row'].last-row-item {
  min-height: 50px;
}
