// === STANDARD DROPDOWN === //
.dropdown {
  .dropdown-toggle.btn {
    border-radius: $custom-select-border-radius;
    border: solid 1px var(--imo-grey);
    background-color: #ffffff;
    color: var(--imo-grey-900);
    height: 50px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  &-menu {
    margin: 0;
    // border-color: transparent;
    box-shadow: 0 0 30px 0 var(--imo-main-shadow);
  }

  &-item {
    font-size: 13px;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--imo-grey-900);

    &:hover {
      background-color: var(--imo-grey-100);
      color: var(--imo-black);
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.dropdown-menu-right {
  left: 0;
  right: auto;
}

//=== NAVBAR LIST DROPDOWN ===//
.navbar-light .navbar-nav .page-menu .show > .nav-link {
  text-align: left;
  padding-left: 20px;

  &:after {
    top: 0;
  }
}

.navbar-light .navbar-nav .page-menu .dropdown .nav-link:after {
  top: 0;
}

.navbar-light .navbar-nav .page-menu .nav-link {
  padding-left: 20px;
}

.header-nav-bg {
  background-image: url(../assets//img/rectangle.svg);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 230px;
  height: 61px;
  margin-bottom: -9px;

  @include media-breakpoint-up(md) {
    width: 251px;
  }
}
[data-theme='dark'] .header-nav-bg {
  background-image: url(../assets//img/rectangle-dark.svg);
}

.navbar-light .page-menu {
  .dropdown-toggle {
    // width: 100%;
    width: 80%;
    margin: 0 auto;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    // padding: 0 !important;
    height: 52px;
    position: relative;
    // left: -5px;

    // &.nav-link {
    //   padding-bottom: 0;
    //   padding-top: 0;
    // }
  }

  .dropdown {
    position: relative;
    z-index: 2;
    // border-top-right-radius: 32px;
    // border-top-left-radius: 32px;
    // padding: 0rem 1rem 0;
    font-family: $imo-base-font;
    // width: 194px;
    // border: 1px solid var(--imo-grey-100);

    &.show {
      width: 188px;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        width: 205px;
      }

      .dropdown-toggle {
        width: 100%;
        font-weight: normal;
        color: var(--imo-grey-900);
        font-size: 13px;

        background: var(--imo-grey-100);
        border-top-right-radius: 32px;
        border-top-left-radius: 32px;
        margin: 0 auto;
        height: 52px;
        width: 188px;

        @include media-breakpoint-up(md) {
          width: 205px;
        }
      }

      .dropdown-menu {
        margin-top: 0;
      }

      .divider {
        width: 100%;
        background: var(--imo-grey-400);
        height: 1px;
        margin: 4px 0;
        &:first-child {
          position: absolute;
          top: 3px;
          left: 0;
          right: 0;
          margin: 0;
        }
      }
      .header-dropdown-container {
        max-height: 347px;
      }
    }

    &-menu {
      background-color: var(--imo-grey-100);
      border: none;
      min-width: 4rem;
      margin: auto;
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 32px;
      border-bottom-left-radius: 32px;
      padding: 0.5rem 0 1.25rem;
      text-align: center;
      box-shadow: none;
      width: 188px;

      @include media-breakpoint-up(md) {
        width: 205px;
      }
    }

    &-item {
      text-align: left;
      padding-left: 42px;
      padding-right: 10px;

      &:hover {
        background-color: var(--white);
      }

      &:active {
        color: initial;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 12px;
      bottom: -10px;
      left: 0;
      background-color: transparent;
    }
  }

  &.open {
    .dropdown {
      z-index: 1000;
      position: relative;

      .dropdown-toggle {
        font-weight: normal;
        color: var(--imo-grey-900);
        font-size: 13px;
      }
    }
  }
}

.navbar-light .page-menu .dropdown-item {
  &:hover {
    background-color: var(--imo-grey-300);
  }
  &:focus,
  &:active {
    background-color: var(--imo-grey-300);
    color: #000;
  }
}
.dropdown-item {
  &:hover {
    background-color: var(--imo-grey-100);
  }
  &:focus,
  &:active {
    background-color: var(--imo-grey-100);
    color: #000;
  }
}

[data-theme='dark'] {
  .navbar-light .page-menu .dropdown-item {
    &:focus,
    &:active {
      color: #fafafa;
      background-color: var(--white);
    }
  }
  .dropdown-item {
    &:focus,
    &:active {
      background-color: var(--imo-grey-100);
      color: #fafafa;
    }
  }
}
