.tab-content {
  width: 100%;
  z-index: 11;
  .card {
    box-shadow: none;
  }
}

.nav-tabs .nav-link {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: var(--imo-grey-900);

  &.active {
    box-shadow: 0 3px 10px 0 var(--imo-grey-500);
    font-weight: normal !important;
    text-shadow: -0.35px -0.35px 0 $imo-grey-700, 0.35px 0.35px var(--imo-grey-700);
  }
}

.dashboard-nav {
  width: 100%;

  &__item {
    width: 50%;

    .nav-link {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &:first-child .nav-link {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:last-child .nav-link {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.investment-bar {
  display: flex;
  flex-flow: row wrap;
  .small {
    margin: 5px 10px 5px 0;
  }
}
