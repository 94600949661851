.navbar {
  padding: 0.25rem 1rem;
  height: 60px;

  @include media-breakpoint-up(md) {
    height: 68px;
  }

  .divider {
    width: 1px;
    height: 37px;
    background: var(--imo-grey-300);
    display: block;
    align-self: center;
  }
}
.container-fluid > .navbar.navbar-light {
  flex-wrap: nowrap;
}
.navbar-brand {
  margin: 0;
}

.navbar-light {
  background-color: var(--white);
  z-index: 999;

  .navbar {
    padding: 0 1rem;

    &-nav {
      @include media-breakpoint-up(lg) {
        align-items: center;
      }

      .nav-link {
        font-family: $imo-base-font;
        font-size: 14px;
        font-weight: bold;
        color: var(--imo-grey-900);
        padding-left: 1rem;
        padding-right: 1rem;
        padding: 0.75rem 1.5rem 0.35rem 1.5rem;
        display: flex;
      }

      .nav-item {
        position: relative;
        margin: 0 3px;
        border-top-right-radius: 32px;
        border-top-left-radius: 32px;
        transition: background-color 0.3s linear;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 12px;
          bottom: -12px;
          left: 0;
          background-color: transparent;
          transition: background-color 0.3s linear;
        }

        &:hover,
        &.active {
          background: var(--imo-grey-100);

          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 12px;
            bottom: -12px;
            left: 0;
            background-color: var(--imo-grey-100);
          }
        }

        &--user {
          &.dropdown {
            z-index: 999;
          }
        }
        &--d-left {
          .dropdown-menu-right {
            left: auto;
            right: 0;
          }
        }

        &--icon {
          &:after {
            display: none;
          }

          &:hover,
          &.active {
            background-color: transparent;
          }

          &.dropdown .nav-link {
            &:after {
              right: -0.2rem;
              top: 2px;
            }
          }
        }
      }

      .dropdown {
        z-index: 100;
        .nav-link {
          &:after {
            content: url(../assets/img/icon-right.svg);
            border: none;
            transform: rotate(90deg);
            transition: transform 0.3s linear;
            position: relative;
            right: -0.8rem;
            top: 0.05rem;
          }
        }

        &.show {
          z-index: 1004;
          .nav-link {
            &:after {
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }
}
[data-theme='dark'] .navbar-light .navbar-nav .dropdown .nav-link:after {
  content: url(../assets/img/icon-right-dark.svg);
}

.header-nav-link {
  display: flex;
  align-items: center;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;

  .badge {
    font-size: 78.6%;
    color: var(--imo-grey-700);
  }

  svg {
    color: var(--imo-grey-700);
  }
}

.navbar-toggler {
  z-index: 101;
  margin-right: 20px;
}

.navbar-nav-mobile {
  margin-left: auto;

  @media screen and (max-width: 1279px) {
    flex-direction: row;
    justify-content: center;
    border-top: 1px solid var(--imo-grey-300);
    border-bottom: 1px solid var(--imo-grey-300);
    padding: 1rem 0;
    margin-top: auto;
    margin-left: 0;
  }

  &--menu {
    @media screen and (max-width: 1279px) {
      align-items: flex-start;
      padding-left: 1rem;
    }
  }
}

.navbar-collapse {
  @media screen and (max-width: 1279px) {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 310px;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    left: -310px;
    z-index: 1010;
    background: var(--white);
    padding-top: 4rem;
    transition: transform 0.3s ease-in-out;
    @media (max-height: 400px) {
      padding-top: 2.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    overflow-y: auto;
  }

  &--open {
    transform: translateX(100%);
  }
}

.navbar-light .navbar-nav .page-menu .show > .nav-link {
  font-weight: normal;
  color: var(--imo-grey-900);
}

.navbar {
  .collapse:not(.show) {
    display: block;
    transform: translateX(-100%);
    transition: translate 0.3s ease;
  }

  .collapse.show {
    //   left: ;
    transition: translate 0.3s ease;
  }
}

/*
 * Just a quick hamburger
 */
.navbar-toggler {
  padding: 0;
  border: none;
  outline: none;
  border-radius: 0;
  height: 20px;
  border-color: transparent;

  &:active,
  &:focus {
    border-color: transparent;
    outline: none;
  }

  span {
    display: block;
    width: 20px;
    height: 2px;
    margin-bottom: 6px;
    position: relative;
    background: var(--imo-grey-700);
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    border-radius: 30px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background-color 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(2) {
      transform-origin: 0% 100%;
    }
  }

  &--open {
    z-index: 1011;
    span {
      opacity: 1;
      transform: rotate(45deg) translate(0px, -2px);
      background: var(--imo-grey-900);

      &:nth-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      &:last-child {
        transform: rotate(-45deg) translate(3px, -5px);
      }
    }
  }
}
.hide-nav-lg {
  display: none;
}
@media (max-width: 1279px) {
  .hide-nav-lg {
    display: block;
  }
  .header-nav-link.header-search-input {
    width: calc(100% - 1.4rem);
    height: 43px;

    i {
      padding-top: 2px;
    }

    #headerSearchInput {
      width: 100%;
      .select__control {
        min-width: auto;
      }
    }
  }
}
