.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  .scrollbar-container {
    box-shadow: 0 0 30px 0 var(--imo-main-shadow);
    z-index: 3;
  }

  &.three-part-sidebar .scrollbar-container {
    flex: 1;
  }

  .modal-body {
    padding: 1.75rem 1.55rem;
    background-color: var(--white);
    overflow: auto;
  }

  &-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    height: 100vh;
    transition: transform 0.15s linear;

    @media (min-width: 576px) {
      max-width: 500px;
      margin-right: 0;
      margin-left: auto;
    }

    @media (min-width: 1024px) {
      max-width: 720px;
      margin-right: 0;
      margin-left: auto;
    }

    &.offer {
      max-width: 80vw;
      margin-right: auto;
      margin-left: auto;
      @media (max-width: 1440px) {
        max-width: 100vw;
      }

      span:not(.info-red) {
        color: var(--imo-grey-900);
      }
      .disabled.dropdown-item .info-red {
        opacity: 0.4;
      }
      .sidebar-header {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }
  }

  &-content {
    background-color: var(--white);
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    background-color: var(--imo-off-white);
    background-clip: padding-box;
    border: 0 solid transparent;
    outline: 0;
    overflow: auto;
    box-shadow: 0 0 30px 0 var(--imo-main-shadow);

    .sidebar-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1.75rem 1.55rem;

      > * {
        align-self: center;
        margin-bottom: 0;
      }

      label {
        margin-bottom: 0;
      }

      .new_filter_link {
        flex: 1;
      }

      .current_filter {
        .select__control {
          min-width: 190px;
        }
      }

      @media (max-width: 1024px) {
        .current_filter {
          order: 1;
          margin-top: 1rem;
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-grow: 1;

          label {
            width: 100%;
            margin-bottom: 5px;
          }

          div.small {
            flex-grow: 1;
            padding-left: 15px;

            .select__indicators,
            .select__control {
              height: 50px;
            }
          }

          button {
            text-indent: -99999px;
          }
        }
      }

      .sidebar-title {
        font-size: 12px;
        letter-spacing: 0.6px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .close {
        opacity: 1;
        position: relative;
        margin-left: 60px;

        span {
          width: 20px;
          height: 20px;
          background-image: url(../assets/img/close.svg);
          background-repeat: no-repeat;
          text-indent: -9999999px;
          display: block;
        }

        &:before {
          content: '';
          height: 20px;
          width: 1px;
          background-color: var(--imo-grey-700);
          opacity: 0.2;
          position: absolute;
          left: -1.55rem;
        }
      }
    }

    @media (max-width: 1024px) {
      .sidebar-header {
        flex-flow: row wrap;
      }
    }
  }

  &.three-part-sidebar &-content {
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.horizontal-menu {
  position: relative;

  .menu-wrapper {
    margin: 0 25px;
    width: 100%;
  }

  .menu-item-wrapper {
    &:first-child .menu-item {
      margin-left: 0;
    }

    //  &:last-child .menu-item {
    //    margin-right: 0px;
    //  }

    .menu-item {
      padding: 0 0 15px 0;
      margin: 0 18px;
      user-select: none;
      cursor: pointer;
      border: none;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0.6px;
      color: var(--imo-grey-700);
      text-shadow: -0.35px -0.35px 0 transparent, 0.35px 0.35px transparent;

      &.active {
        border-bottom: 2px var(--imo-orange) solid;
        text-shadow: -0.35px -0.35px 0 $imo-grey-700, 0.35px 0.35px var(--imo-grey-700);
        color: var(--imo-grey-900);
        font-weight: normal;
      }
    }

    &:focus {
      outline: 0;
    }
  }

  .scroll-menu-arrow {
    cursor: pointer;
    position: absolute;
    width: 50px;
    height: 41px;
    z-index: 2;
    top: 0;

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }

    &.scroll-menu-arrow--disabled {
      display: none;
    }

    .arrow-next {
      background: linear-gradient(270deg, rgba(252, 252, 252, 1) 70%, rgba(252, 252, 252, 0) 100%);
      width: 100%;
      height: 100%;
      text-align: center;
    }

    .arrow-prev {
      background: linear-gradient(90deg, rgba(252, 252, 252, 1) 70%, rgba(252, 252, 252, 0) 100%);
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
}

[data-theme='dark'] .horizontal-menu .scroll-menu-arrow {
  .arrow-next,
  .arrow-prev {
    background: transparent;
    & svg path {
      fill: var(--imo-grey-900);
    }
  }
}

.sidebar-dialog.sidebar-entered {
  transition: 150ms ease;
}

.sidebar-dialog.sidebar-entered.xl {
  max-width: 90vw;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
}
.sidebar-dialog.xs {
  max-width: 370px;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
}

.sidebar-entering {
  transform: translateX(100%);
}

.sidebar-entered {
  transform: translateX(0%);
}

.sidebar-exiting {
  transform: translateX(100%);
}

.sidebar-exit {
  transform: translateX(100%);
}

.sidebar-exited {
  transform: translateX(100%);
}

// naprawia wysokość dla mobilnych urządzeń z otwarnym inputem url
.sidebar {
  .sidebar-dialog,
  .sidebar-content {
    height: 100%;
    min-height: 100%;
  }
}
