.rbc-calendar {
  padding: 25px 0 0 25px;
  .rbc-today {
    background: none;
  }
  .rbc-time-content {
    border-top: 1px solid #ddd;
  }
  .rbc-label.rbc-time-header-gutter {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &::after {
      font-size: 12px;
      color: var(--imo-grey-700);
      content: 'Cały dzień';
      display: flex;
      text-align: center;
    }
  }
  .rbc-time-view {
    border: none !important;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: none;
  }
  .rbc-time-gutter.rbc-time-column {
    .rbc-timeslot-group {
      border: none !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:first-child {
        & .rbc-label {
          top: -12px;
        }
      }
      & .rbc-label {
        padding-right: 15px;
        font-size: 12px;
        letter-spacing: 0.6px;
        color: var(--imo-grey-700);
        position: relative;
        top: -22px;
      }
    }
  }
  .rbc-day-slot.rbc-time-column {
    .rbc-timeslot-group {
      border: none !important;
      &:first-child {
        & .rbc-time-slot:first-child {
          border: none;
        }
      }
      & .rbc-time-slot:first-child {
        box-shadow: -17px -10px 0px -10px var(--imo-grey);
        border-top: 1px solid #ddd;
      }
      & .rbc-time-slot {
        border: none;
      }
    }
  }
  .rbc-header {
    display: inline-table;
    font-size: 12px;
    color: var(--imo-grey-700);
    padding: 5px 0;
    border: none;
  }
  .rbc-month-header {
    & .rbc-header {
      border-bottom: 1px solid #ddd;
    }
  }
  .rbc-month-view {
    border: none;
  }
  .rbc-date-cell {
    padding-top: 5px;
    text-align: center;
    font-size: 12px;
    color: var(--imo-grey-900);
  }
  .rbc-date-cell.rbc-off-range-bg {
    color: #c5c5c5 !important;
  }
  .rbc-day-bg.rbc-off-range-bg {
    background: none;
  }
  .rbc-off-range {
    color: var(--imo-grey) !important;
  }
  .rbc-time-content {
    @include scrollbars(1em, var(--imo-grey), white);
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      border: 5px solid var(--white);
    }
    &::-webkit-scrollbar {
      width: 17px;
    }
  }
  .rbc-current-time-indicator {
    display: none;
  }
  .rbc-events-container {
    margin-right: 0 !important;
    border-left: 1px solid #ddd;
    .rbc-event {
      outline: none;
      border-radius: 15px;
      padding: 3px 10px;
      z-index: 1;
      border: 4px solid var(--white);
      border-top: none;
      border-bottom: none;
    }
  }

  .rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .rbc-addons-dnd-resize-ns-anchor,
  .rbc-addons-dnd-resize-ew-anchor {
    display: none;
  }
  .rbc-event {
    outline: none !important;
  }
  .rbc-addons-dnd-resizable,
  .rbc-addons-dnd-drag-preview {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: flex-end;
    & .rbc-event-content {
      flex: none;
      height: auto;
    }
  }
  .rbc-event-content {
    font-weight: 600;
    font-size: 15px;
  }
  .rbc-event-label {
    font-size: 13px;
  }
  .week-header-day {
    font-size: 13px;
  }
  .week-header-date {
    color: var(--imo-grey-900);
    font-size: 13px;
  }
  .rbc-month-row .rbc-event {
    background: initial !important;
  }
  .rbc-show-more {
    margin-left: 3px;
  }
  .rbc-allday-cell {
    border-top: 1px solid #ddd;
  }
}
.rbc-calendar-day {
  .rbc-allday-cell {
    border-top: none;
  }
}
.rbc-calendar-week,
.rbc-calendar-day {
  .rbc-row-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .rbc-row {
    min-height: 0px !important;
    &:first-child {
      min-height: 20px !important;
    }
  }
}
.rbc-calendar-week {
  .rbc-header {
    margin-bottom: 10px;
  }
  .rbc-label.rbc-time-header-gutter {
    justify-content: flex-start;
  }
  .rbc-row.rbc-time-header-cell {
    position: relative;
    top: -10px;
  }
  .rbc-time-header.rbc-overflowing {
    margin-right: 17px !important;
  }
}
.rbc-calendar-year {
  .rbc-calendar {
    padding: 10px 0 0 25px;
  }
}

.react-datepicker__day--disabled {
  opacity: 0.3;
  text-decoration: line-through;
}