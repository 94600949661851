.modal-content {
  box-shadow: 0 0 30px 0 var(--imo-main-shadow);

  .modal-header {
    .modal-title {
      font-size: 12px;
      letter-spacing: 0.6px;
      font-weight: 800;
      text-transform: uppercase;
      color: var(--imo-grey-900);
    }
  }

  .modal-body {
    padding-left: 1.55rem;
    padding-right: 1.55rem;
    padding-top: 0;
  }

  .modal-footer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    & > :first-child {
      margin-left: 0;
    }
    & > :last-child {
      margin-right: 0;
    }
  }
  .settings-footer-btn-r {
    margin-right: -0.6rem;
  }
  .custom-control-label {
    font-size: 0.87rem;
    letter-spacing: 0.6px;
    color: var(--imo-grey-900);
    line-height: 24px;
  }

  .custom-checkbox {
    margin-bottom: 10px;
  }

  .title_col {
    color: var(--imo-grey-700);
    font-size: 0.75rem;
    letter-spacing: 0.6px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .maxWrap {
    max-height: 50vh;
    overflow: hidden;
  }

  .info-red {
    color: var(--imo-red);
    font-size: 0.875rem;
  }
  .info-green {
    color: var(--imo-green);
    font-size: 0.875rem;
  }
}

.modal-header .close {
  opacity: 1;
  position: relative;

  span {
    width: 20px;
    height: 20px;
    background-image: url(../assets/img/close.svg);
    background-repeat: no-repeat;
    text-indent: -9999999px;
    display: block;
  }

  &:before {
    content: '';
    height: 20px;
    width: 1px;
    background-color: var(--imo-grey-700);
    opacity: 0.2;
    position: absolute;
    left: 0;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 750px;
  }
  .w-50 {
    float: left;
  }
}

.modal-sm {
  max-width: calc(100vw - 2rem);
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 720px) {
    max-width: 600px;
  }
  .modal-content {
    margin: 0 auto;
  }
}

.modal-lg {
  min-height: 100vh;
  margin: 0 auto 0 auto;
  .modal-content {
    height: 100vh;
    border-radius: 0;
  }
  .modal-body {
    max-height: 100%;
  }
  @media (min-width: 768px) {
    max-width: 1140px;
  }
}
.modal-dialog:not(.modal-lg) {
  .modal-content {
    @media (min-height: 601px) and (min-width: 769px) {
      max-height: calc(100vh - 3.5rem);
      & > form {
        max-height: calc(100vh - 3.5rem);
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.modal-full {
  min-height: 100vh;
  margin: 0 auto 0 auto;
  .modal-content {
    max-height: 100vh !important;
    height: 100vh;
    border-radius: 0;
  }
  max-width: 100vw;
}
div.modal-dialog:not(.modal-sm):not(.modal-xs),
div.mobile-fullscreen {
  @media (max-width: 768px), (max-height: 600px) {
    height: 100%;
    min-height: 100%;
    div.modal-content {
      height: 100%;
      min-height: 100%;
      & > form {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
      }
    }
    min-width: 100vw;
    margin: 0 auto 0 auto !important;
    .modal-body {
      max-height: 100%;
      height: 100%;
    }
    .modal-content {
      border-radius: 0;
    }
  }
}

.modal-dialog {
  @media (max-width: 420px), (max-height: 420px) {
    height: 100%;
    min-height: 100%;
    div.modal-content {
      height: 100%;
      min-height: 100%;
    }
    min-width: 100vw;
    margin: 0 auto 0 auto !important;
    .modal-body {
      max-height: 100%;
      height: 100%;
    }
    .modal-content {
      border-radius: 0;
    }
  }
}
.modal-xs {
  max-width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  @media (min-width: 720px) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 720px) {
    &.investment {
      max-width: 440px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.modal-wide {
  max-width: 1200px;
  @media (max-width: 940px) {
    max-width: 100vw;
    max-height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0 auto 0 auto !important;
    .modal-body {
      max-height: 100%;
    }
    .modal-content {
      max-height: 100vh !important;
      height: 100vh;
      border-radius: 0;
    }
    .modal-header {
      border-radius: 0 !important;
    }
  }
  @media (max-height: 600) {
    .modal-header {
      border-radius: 0 !important;
    }
  }
}
.modal-secondary-text {
  font-size: 14px;
  letter-spacing: 0.6px;
  color: rgb(121, 121, 121);
}
[data-theme='dark'] .modal-backdrop {
  opacity: 0.4;
  background-color: #474747;
}
[data-theme='dark'] .modal-body-with-tabs {
  background-color: var(--white);
}
