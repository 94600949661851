.card-header {
  .tab-content & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.card-header-alert {
  color: var(--imo-red);
  font-size: 13px;
  background-color: var(--imo-grey-300);
  padding: 0.75rem 1.25rem;
}
[data-theme='dark'] .card-header-alert {
  background-color: var(--imo-grey-100);
}

.card-header-title {
  color: var(--imo-grey-900);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
}

.card {
  box-shadow: 0 0 18px 0 var(--imo-main-shadow);
}

.card-body {
  background-color: var(--white);

  .tab-content & {
    padding-bottom: 0;
  }
}
