// Here you can add other styles
.legend {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;

  &:empty {
    display: inline-block !important;
  }
}

.separator {
  width: 1px;
  background-color: var(--imo-grey-300);
  height: 20px;
  margin: 0 10px;
  display: flex;
  align-self: center;
}

.ps--active-y > .ps__rail-y {
  z-index: 99;
}

.ps--active-x > .ps__rail-x {
  z-index: 99;
}

ul.offers-to-delete {
  border: 1px dotted var(--imo-grey);
  padding: 6px;
  border-radius: 4px;
  margin: 10px 0;
  list-style-type: none;
  float: left;
  width: 100%;

  li {
    background-color: var(--imo-grey-300);
    color: var(--imo-grey-900);
    border-radius: 4px;
    float: left;
    margin: 4px;
    padding: 6px;
    font-size: 13px;
  }
}

.nav-tabs {
  margin-bottom: 1rem;

  &.filters-administrative-agents {
    margin-top: 5px;

    .nav-item {
      width: 50%;
      text-align: center;
    }
  }

  .nav-link {
    padding: 0.6rem 1rem;
    font-weight: normal;
    border: 1px solid var(--imo-grey);
    border-radius: 0;
    margin-left: -1px;
    text-transform: none;
    letter-spacing: 0.6px;
    font-size: 14px;
    text-shadow: -0.35px -0.35px 0 transparent, 0.35px 0.35px transparent;

    &:hover:not(.active) {
      border-color: var(--imo-grey-700);
      cursor: pointer;
    }

    &.active {
      box-shadow: none;
      font-weight: normal;
      background: transparent;
      position: relative;
      text-shadow: -0.35px -0.35px 0 var(--imo-grey-700), 0.35px 0.35px var(--imo-grey-700);
      color: var(--imo-grey-900);

      &:before {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        background-color: var(--imo-orange);
        position: absolute;
        top: -1px;
        left: 0;
      }
    }
  }
}
[data-theme='dark'] .tooltip-inner {
  background-color: var(--imo-grey-100) !important;
  box-shadow: 0 0 30px 0 var(--imo-main-shadow) !important;
}

.imo-tooltip {
  z-index: 999;
  max-width: 570px;
  padding: 0 !important;
  opacity: 1 !important;
  background-color: transparent !important;

  .card {
    border-radius: 16px;
    color: var(--imo-grey-700);
    font-size: 13px;
    letter-spacing: 0.6px;
    font-family: $imo-base-font;
    white-space: nowrap;

    .icon {
      margin-right: 6px;
    }

    strong {
      font-weight: bold;
      color: var(--imo-grey-900);

      &.price {
        font-size: 16px;
        letter-spacing: 0;
      }
    }

    .card-footer {
      border-top: 1px solid var(--imo-grey-500) !important;
      padding-bottom: 20px;
      padding-top: 20px;

      .row {
        .col {
          line-height: 24px;
        }
      }

      &:last-child {
        border-radius: 0 0 16px 16px;
      }
    }
    .card-header {
      padding-bottom: 0;

      &:first-child {
        border-radius: 16px 16px 0 0;
      }
    }
  }
}

.input-group {
  &.offer-show {
    .btn-imo {
      height: 40px;
      align-self: center;
    }
  }
  &.custom {
    input {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
      margin-right: 10px;
    }
  }
}

label {
  margin-bottom: 5px;
}
.custom-scroll,
[data-theme='dark'] .select__menu-list {
  overflow: auto;
  @include scrollbars(0.5em, var(--imo-grey), transparent);
}
.custom-scroll.column {
  &::-webkit-scrollbar {
    width: 14px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
  }
}
.custom-scroll.offer::-webkit-scrollbar {
  width: 15px;
  height: 6px;
}

.info-red {
  color: var(--imo-red);
  font-size: 0.875rem;
}
.info-red-i {
  color: var(--imo-red) !important;
  font-size: 0.875rem;
}
.invoices-icon-btn {
  cursor: pointer;
  display: flex;
}
.invoices-table-footer {
  i {
    font-size: 0.7rem !important;
  }
  label {
    margin: 0;
  }
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: #f9cb99;
  background-color: #f9cb99 !important;
}
.custom-checkbox.custom-control.select-option-deleted {
  .custom-control-label,
  label,
  & {
    color: #ff0000 !important;
    text-decoration: line-through;
  }
}
.tooltip-inner {
  padding: 10px 16px;
  background-color: var(--imo-grey-900);
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(204, 204, 204, 50);
}

.tooltip.show.bs-tooltip-auto {
  opacity: 1;
}
.cursor-pointer {
  cursor: pointer;
}
.tooltip-white {
  .tooltip-inner {
    max-width: 400px;
    text-align: left;
    border-radius: 16px;
    padding: 20px 25px;
    background: var(--white);
    color: var(--imo-grey-700);
  }
  &.tooltip-width-md {
    .tooltip-inner {
      max-width: 300px;
    }
  }
}
.tooltip.show {
  pointer-events: none;
}

.tooltip-shadow {
  .tooltip-inner {
    box-shadow: 0 0 30px 0 var(--imo-main-shadow);
  }
}

.tooltip-black {
  .tooltip.show.bs-tooltip-auto {
    opacity: 1;
  }
  .tooltip-inner {
    min-width: auto;
    max-width: 400px;
    text-align: left;
    border-radius: 16px;
    padding: 10px 15px;
    background: var(--imo-grey-900);
    color: var(--white);
    box-shadow: 0 0 30px 0 var(--imo-main-shadow);
  }
}
[data-theme='dark'] .tooltip-black .tooltip-inner {
  color: var(--imo-grey-900);
}

.tooltip-red {
  .tooltip-inner {
    min-width: auto;
    max-width: 200px;
    text-align: left;
    border-radius: 16px;
    padding: 20px 25px;
    background: #ff0056;
    color: var(--white);
    box-shadow: 0 0 30px 0 var(--imo-main-shadow);
  }
}

.tooltip-big {
  .tooltip-inner {
    max-width: 600px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.gray-gradient {
  background-image: linear-gradient(to right, var(--imo-grey-300), var(--imo-grey) 100%) !important;
  color: #000 !important;
}

[data-rbd-drag-handle-context-id],
[data-rbd-drag-handle-draggable-id] {
  pointer-events: auto !important;
  cursor: all-scroll !important;
}
.right-bottom-tooltip {
  .tooltip {
    top: 13px !important;
  }
}
div.tox-tinymce {
  border-radius: 0.25rem !important;
  border-color: var(--imo-grey);
}
div.tox {
  .tox-edit-area__iframe {
    background: var(--white);
  }
  .tox-toolbar__primary {
    border-bottom: 1px solid var(--imo-grey);
    background: var(--white);
  }
  .tox-statusbar {
    background: var(--white);
    border-color: var(--imo-grey);
  }
  .tox-tbtn svg {
    fill: var(--imo-grey-900);
  }
  .tox-statusbar__wordcount {
    color: var(--imo-grey-900);
  }
  .tox-dialog-wrap__backdrop {
    opacity: 0.3;
    background-color: var(--imo-grey-900);
  }
  .tox-dialog__body-content {
    @include scrollbars(0.5em, var(--imo-grey), transparent);
  }
}
[data-theme='dark'] div.tox {
  .tox-tbtn--enabled svg {
    fill: var(--imo-grey-100);
  }
  .tox-edit-area__iframe,
  .tox-toolbar__primary,
  .tox-statusbar {
    background-color: var(--imo-grey-100);
  }
  .tox-tbtn {
    transition: all 100ms ease;
    &:hover {
      background-color: var(--imo-grey-900);
      .tox-tbtn__select-label {
        color: var(--imo-grey-100);
      }
    }
  }
  .tox-menu {
    box-shadow: 0 0 7px 0 var(--imo-main-shadow);
    background-color: var(--white);
    border-color: var(--white);
    .tox-collection__item {
      color: var(--imo-grey-900);
      &:hover,
      &:focus,
      &:active,
      &.tox-collection__item--enabled,
      &.tox-collection__item--active {
        background-color: var(--imo-grey-100) !important;
      }
    }
  }
  .tox-dialog-wrap__backdrop {
    opacity: 0.4;
    background-color: #474747;
  }
  .tox-dialog {
    border: 2px solid var(--imo-grey-100);
    background-color: var(--imo-off-white);
    * {
      background-color: var(--imo-off-white);
      color: var(--imo-grey-900);
    }
    .tox-dialog__body-content {
      h1,
      h2,
      h3 {
        color: var(--imo-blue);
      }
      a {
        color: var(--imo-blue);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .tox-dialog__footer {
      border-top: 1px solid var(--imo-grey-100);
    }
  }
}
[data-theme='dark'] .visible-editor:not(.editor-invalid) .tox-tinymce {
  border-color: var(--imo-grey-100);
}
.highlightWithinTextarea {
  textarea {
    width: 100%;
    border: 1px solid var(--imo-grey);
    border-radius: 0.25rem;
  }
  .form-control {
    font-family: 'Lato', sans-serif !important;
    font-size: 0.8125rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: var(--imo-grey-900) !important;
  }
  .form-control {
    &:hover {
      border-color: var(--imo-grey-900);
    }
    &:focus {
      border-color: var(--imo-blue) !important;
    }
    &.is-invalid:focus {
      border-color: var(--imo-red) !important;
    }
    &.is-invalid:hover {
      border-color: var(--imo-red) !important;
    }
  }
}
.highlightWithinTextarea > div > div > div {
  padding: 0.95rem 1.25rem;
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  span {
    display: none;
  }
  mark {
    background-color: #d2edff;
  }
}
[data-theme='dark'] .highlightWithinTextarea > div > div > div mark {
  background-color: #245a7e;
}
.dropdown-menu {
  max-height: 85vh;
  overflow: auto;
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1111;
}
.modal {
  overflow: hidden !important;
}
.horizontal-menu .scroll-menu-arrow {
  width: 25px;
  top: -1px;
}

.align-items-fix {
  align-items: flex-start;
  @media (min-width: 768px) {
    align-items: center;
  }
}
.select__option {
  @media (max-width: 1080px) {
    white-space: break-spaces !important;
  }
}
.col-sm-2.col-lg-1 label {
  white-space: nowrap;
}

.leaflet-container {
  height: calc(100vh - 166px);
}
dd {
  margin: 0;
}
div.Toastify__toast--success {
  background: #07bc0c;
  color: #fff;
  .Toastify__progress-bar {
    background: #fff;
  }
  .Toastify__close-button {
    color: #fff;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
  .Toastify__toast-icon svg {
    fill: #fff;
  }
}
div.Toastify__toast--error {
  background: #e74c3c;
  color: #fff;
  .Toastify__progress-bar {
    background: #fff;
  }
  .Toastify__close-button {
    color: #fff;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
  .Toastify__toast-icon svg {
    fill: #fff;
  }
}

.introjs-button {
  position: relative !important;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #ee7d00 0%, #ff4200 50%, #ee7d00 100%) !important;
  border: none !important;
  background-repeat: no-repeat !important;
  background-size: auto 200% !important;
  background-position: 0 0;
  box-shadow: none !important;
  transition: 0.25s !important;
  letter-spacing: 0.6px !important;
  padding: 0.9rem 1.4rem !important;
  font-size: 0.75rem !important;
  line-height: 1 !important;
  border-radius: 20px !important;
  color: #fff !important;
  text-shadow: none !important;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.introjs-button.introjs-prevbutton {
  color: #3b87ba !important;
  border-color: #3b87ba !important;
  border: 2px solid !important;
  padding: 0.775rem 1.437rem !important;
  background: transparent !important;
}

.introjs-tooltip-title {
  font-size: 12px !important;
  color: #35393c !important;
  letter-spacing: 0.6px !important;
}

.introjs-dontShowAgain {
  margin-bottom: 15px;
  padding-left: 1.55rem !important;
  padding-right: 1.55rem !important;
  display: none;

  label {
    background-color: transparent !important;
    letter-spacing: 0;
    color: #333 !important;
    font-size: 0.87rem !important;
  }
  input {
    width: 12px !important;
    height: 12px !important;
  }
}

.firstTooltip .introjs-dontShowAgain {
  display: block;
}

.introjs-dontShowAgain label {
  margin: 0 !important;
}

.introjs-dontShowAgain {
  margin-bottom: 10px;
  //display: inline-flex;
  margin-right: 1rem;
  min-height: 1.75rem;
  input {
    display: none !important;
  }

  label {
    font-size: 0.87rem;
    letter-spacing: 0.6px;
    padding-left: 32px !important;
    position: relative;
    color: var(--imo-grey-900);
    line-height: 24px;
    font-weight: normal;
    cursor: pointer;
    display: flex;
    align-items: center;

    .checkbox-span {
      position: absolute;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 0.25rem;
      border: 1px solid var(--imo-grey);
      transform: translate3d(0, 0, 0);
      transform: scale(1);
      transition: all 0.2s ease;

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        background: var(--imo-primary);
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
      }
      &:after {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        display: block;
        width: 24px;
        height: 24px;
        content: '';
        background: 50% / 50% 50% no-repeat;
      }
    }
    input:not(:disabled):active + .checkbox-span::before {
      color: var(--white);
      background-color: var(--white);
      border-color: var(--imo-grey-700);
    }
  }
  input:checked + label {
    .checkbox-span {
      background: var(--imo-primary);
      border-color: var(--imo-primary);
      &:before {
        pointer-events: none;
      }
      &:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9' viewBox='5.5 8 14 9'%3e%3cpath id='check' fill='%23FFFFFF' d='M19.207,8.277c0.36,0.342,0.389,0.879,0.084,1.251l-0.084,0.089l-7.5,7.107 c-0.15,0.142-0.336,0.229-0.531,0.262L11.059,17h-0.118c-0.197-0.011-0.39-0.076-0.554-0.197l-0.094-0.078l-4.5-4.265 c-0.391-0.37-0.391-0.97,0-1.34c0.36-0.341,0.928-0.368,1.32-0.079l0.094,0.079L11,14.713l6.793-6.436 C18.184,7.908,18.816,7.908,19.207,8.277z'/%3e%3c/svg%3e");
      }
    }
  }
  input.animate-checkbox:checked + label .checkbox-span {
    animation: wave 0.4s ease;
    &:before {
      transform: scale(3);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }
  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
}

.introjs-disabled,
.introjs-disabled.introjs-button:hover {
  background-color: transparent !important;
  color: lightgrey !important;
}

.introjs-button:hover {
  background-position: 0% 100% !important;
  border-color: transparent !important;
  color: #fff !important;
  background-color: #c86900 !important;
}

.introjs-button.introjs-prevbutton:hover {
  color: #fff !important;
  background-color: #3b87ba !important;
  border-color: #3b87ba !important;
}

.introjs-button:focus,
.introjs-button:active {
  border: none !important;
  box-shadow: none !important;
}

.introjs-button.introjs-prevbutton:active,
.introjs-button.introjs-prevbutton:focus {
  border: 2px solid !important;
}

.introjs-disabled,
.introjs-disabled:hover {
  background-color: none !important;
}

.introjs-tooltip {
  padding: 1.75rem 0 0 !important;
  background-color: #fcfcfc !important;
  border-radius: 16px !important;
}

.introjs-arrow.left,
.introjs-arrow.right {
  top: 16px !important;
}

.introjs-arrow.top,
.introjs-arrow.bottom {
  left: 16px !important;
}

.introjs-tooltiptext {
  padding: 0.8rem 1.55rem 0.8rem 1.55rem !important;
  text-align: left;
  font-size: 0.87rem;
  color: #797979;
}

.introjs-tooltip-header {
  margin: 0 1.55rem 0 1.55rem !important;
  padding: 0 0 1rem 0 !important;
}
.introjs-skipbutton {
  padding: 0;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  right: 6px !important;
  text-indent: -9999999px;
  background-image: url(../assets/img/close.svg);
  background-repeat: no-repeat;

  &:before {
    content: '';
    height: 20px;
    width: 1px;
    background-color: var(--imo-grey-700);
    opacity: 0.2;
    position: absolute;
    left: -1.55rem;
  }
}

.introjs-skipbutton:hover {
  color: #ee7d00;
}

.introjs-bullets {
  display: none;
}

.introjs-tooltipbuttons {
  padding: 0.75rem 1.55rem !important;
}

.header-search-input {
  &:hover {
    background-color: transparent !important;
    cursor: pointer;

    &::after {
      background-color: transparent !important;
    }
  }

  .select__control {
    margin-top: 4px;
  }

  .select__placeholder {
    left: 50px !important;
  }

  i {
    color: var(--imo-grey-700);
    margin-top: 4px;
    margin-left: 12px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:hover {
      color: var(--imo-grey-secondary-500);
    }
  }

  .icon-close {
    font-size: 12px;
    margin-left: 12px;

    &:hover {
      color: #606060;
    }
  }
  .select__value-container {
    padding-left: 0 !important;
    margin-left: -1px !important;
    padding-top: 0 !important;
    padding-bottom: 4px !important;

    > div {
      min-height: 33px !important;
    }
    span {
      position: inherit;
    }
  }

  .select__input-container {
    transform: translateY(-55%) !important;
    width: stretch;

    .select__input {
      width: stretch;
      position: absolute;
      max-width: 200px;
    }
  }

  .select__indicator {
    svg {
      color: hsl(0, 0%, 80%);

      &:hover {
        color: hsl(0, 0%, 60%);
      }
    }
  }
}

.animation-gradient {
  background: linear-gradient(-45deg, #ee7d00, #e73c7e, #ee7d00, #e73c7e);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  overflow: hidden;
  width: 100vw;
  height: 100%;

  .waviy {
    position: relative;
  }
  .waviy span {
    position: relative;
    display: inline-block;
    font-size: 32px;
    font-weight: 300;
    color: var(--white);
    margin: 20px 0 0;
    letter-spacing: 8px;
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
  .splash-logo {
    height: 80%;
    max-width: 100%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.navbar-light .navbar-nav .nav-link.header-no-dropdown {
  padding: 0.75rem 0.5rem 0.35rem;
  margin: 0 3px;
  @media (max-width: 1279px) {
    margin: 0;
    padding: 0.75rem 1.5rem 0.35rem 1.5rem;
  }
}

.tooltip-container.fade {
  z-index: 1111;
  position: relative;
}

.scrollbar-container {
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: var(--imo-grey-secondary-200);
  }
  .ps__thumb-y {
    background-color: var(--imo-grey-secondary-100);
  }
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: var(--imo-grey-secondary-200);
  }
  .ps__thumb-x {
    background-color: var(--imo-grey-secondary-100);
  }
  &.ps .ps__rail-x:hover,
  &.ps .ps__rail-y:hover,
  &.ps .ps__rail-x:focus,
  &.ps .ps__rail-y:focus,
  &.ps .ps__rail-x.ps--clicking,
  &.ps .ps__rail-y.ps--clicking {
    background-color: var(--imo-grey-secondary);
  }
}

p.text-muted {
  color: var(--imo-grey-700) !important;
}

div {
  .tox .tox-toolbar,
  .tox .tox-toolbar__primary,
  .tox .tox-toolbar__overflow {
    background-color: var(--white);
  }
}

[data-theme='dark'] div.react-datepicker {
  background-color: var(--white);
  .btn svg path {
    fill: var(--imo-grey);
  }
  .react-datepicker__day--outside-month {
    opacity: 0.3;
  }
  .react-datepicker__day:not(.react-datepicker__day--weekend):not(.react-datepicker__day--selected):hover {
    color: var(--white) !important;
  }
  .react-datepicker__day:not([disabled]):not(.react-datepicker__day--outside-month):hover {
    background-color: var(--imo-grey-secondary-100);
  }
}
a {
  color: var(--imo-blue);
}
a:hover {
  color: #53baff;
}
[data-theme='dark'] {
  .modal-content,
  .dropdown-menu {
    border: 2px solid var(--imo-grey-100);
  }
  .dropdown-divider {
    border: 1px solid var(--imo-grey-100);
  }
  .modal-footer {
    border-top: 1px solid var(--imo-grey-100);
  }
  .separator {
    background-color: var(--imo-grey-100);
  }
}

.tooltip-checkbox {
  pointer-events: auto !important;
}

#location-search .break-spaces .custom-control-inline {
  min-height: auto;
}

#location-search .break-spaces .custom-control-label {
  white-space: break-spaces;
  display: inline;
  align-self: center;
}

#location-search .select__control .select__multi-value__label {
  white-space: normal !important;
}

.YoutubeEmbed {
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  &.show {
    height: 406px;
  }
}

.searchStamp {
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  margin-bottom: 5px;

  &:focus {
    &::placeholder {
      color: var(--imo-grey);
      font-weight: 400;
      opacity: 1;
    }
    &::-moz-placeholder {
      /* Firefox 18- */
      color: var(--imo-grey) !important;
      opacity: 1 !important;
      font-weight: 400 !important;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--imo-grey) !important;
      opacity: 1 !important;
      font-weight: 400 !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--imo-grey) !important;
      opacity: 1 !important;
      font-weight: 400 !important;
    }
    &::-ms-input-placeholder {
      /* IE 10+ */
      color: var(--imo-grey) !important;
      opacity: 1 !important;
      font-weight: 400 !important;
    }
    &::-moz-placeholder {
      /* Firefox 18- */
      color: var(--imo-grey) !important;
      opacity: 1 !important;
      font-weight: 400 !important;
    }
  }

  &:focus-visible {
    text-shadow: none !important;
  }

  &::placeholder {
    color: var(--imo-grey);
    font-weight: 400;
    opacity: 1;
  }
}

.h50.fr4 .select__menu > div > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 576px) {
  .modal-size-654 {
    max-width: 654px;
    .modal-content {
      padding-bottom: 20px;
    }
  }
}

.custom-filter-input {
  height: 30px;
  padding: 0.375rem 0.65rem;
  display: block;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--imo-grey-900);
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid var(--imo-grey);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.exports-table .scrollbar-container div > div:first-child,
.exports-table > div > div > div div:first-child {
  flex: 0 0 auto !important;
}

.magic-wand-button {
   padding-left: 45px;
   margin-bottom: 10px;
}

.magic-wand-button:hover .magic-wand {
  fill: #fff;
}

.magic-wand-button[disabled]:hover .magic-wand {
  fill: var(--imo-blue);
}

.magic-wand {
  left: 20px;
  top: 10px;
  width: 15px;
  position: absolute;
  fill: var(--imo-blue);
  transition: 0.15s ease-in-out;
}

.ai-copy-buttons {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  justify-content: end;
}

.ai-copy-buttons > div {
  margin-bottom: 0 !important;
}

@supports (-webkit-touch-callout: none) {
  input, textarea {
      font-size: 16px !important;
  }
}