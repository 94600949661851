// Button Ghost
@mixin button-ghost-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: transparent;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
    border-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
      }
    }
  }
  @at-root #{str-replace(#{&}, ".btn-", ".show > .btn-")}.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
      }
    }
  }
}

// Button Thirty
@mixin button-thirty-variant(
  $color,
  $color-hover: darken($color, 10%),
  $active-background: $color,
  $active-border: $color
) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: transparent;

  &:hover {
    color: $color-hover;
    background-color: transparent;
    background-image: none;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    color: $color-hover;
    background-color: transparent;
    background-image: none;
    border-color: transparent;
  }

  &.disabled,
  &:disabled {
    color: lighter($color, 7.5%);
    background-color: transparent;
    border-color: transparent;
  }
}
