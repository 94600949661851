html {
  height: 100%;
  min-height: -webkit-fill-available;
  body,
  #root,
  .c-main {
    height: 100%;
  }
  .container-fluid {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

body {
  min-height: 100%;
  margin: 0;
  background-color: var(--imo-grey-100);
  font-family: $imo-base-font;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color: var(--imo-grey-900);
  &.dashboard {
    overflow-y: hidden;
  }

  &:after {
    content: '';
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    pointer-events: none;
    transition: background-color 0.3s linear;
  }

  &.show-bg {
    &:after {
      display: block;
    }
    .navbar-light {
      z-index: inherit;
    }
  }
}

.main {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  padding: 11px 15px 0 15px;
  @media (max-width: 768px), (max-height: 600px) {
    bottom: 0;
    width: 100%;
  }
}
.container-fluid {
  padding: 0;
}
.no-scroll {
  overflow-y: hidden;
}

.dashboard-area {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
