@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import 'react-toastify/dist/ReactToastify.css';
// If you want to override variables do it here
@import 'variables';

// Import Bootstrap 4 styles
@import '~bootstrap/scss/bootstrap.scss';

@import './mixins/scrollbar';
@import './mixins/buttons';
@import './base';
@import './dropdown';
@import './select';
@import './collapse-card';
@import './card';
@import './grid-box';
@import './modal';
@import './icons';
@import './forms';
@import './buttons';
@import './avatars';
@import './navbar';
@import './sidebar';
@import './table';
@import './dashboard';
@import './calendar';

// If you want to add something do it here
@import 'custom';

@import './root';
